import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BatchContext } from "../../../contexts/BatchContext";

export const SkipSMSAndDraw = ({ documents, emailId }) => {
    const navigate = useNavigate();

    const {batchAttachedImages } = useContext(BatchContext);

    useEffect(() => {
      let link = "/sign";
      if (documents.SignerDTO.AttachIdDocImages && typeof attachedImages === 'undefined' && !batchAttachedImages) {
        link = "/attach-images";
    }

        navigate(link, {
            state: {
              documents,
              emailId,
            }
          });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
}