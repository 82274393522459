import { Modal, Form, Input } from 'antd';
import { Col, Row } from 'react-bootstrap';
import documentLoadingGif from './../../assets/images/logo_vidsigner_green.gif';
import whiteLoaderGif from './../../assets/images/white_loader.gif';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { getEvidenceData } from '../../util/getEvidenceData';
import RejectedSignatureService from '../../services/RejectedSignatureService';
import { useNavigate } from 'react-router-dom';
import { BatchContext } from '../../contexts/BatchContext';
import PendingDocumentService from '../../services/PendingDocumentService';
import { CheckIcon } from '../SvgComponents/CheckIcon/CheckIcon';
import { IS_CUSTOM } from '../../constants/GeneralConstants';

import './RejectDocument.css';
import { CancelIcon } from '../SvgComponents/CancelIcon/CancelIcon';

export const RejectDocument = ({ openRejectModal, setOpenRejectModal, signerGuid, emailId, docGuid, email }) => {
    const [rejectionReason, setRejectionReason] = useState("");
    const [isEmptyRejectionReason, setIsEmptyRejectionReason] = useState(false);
    const [isLoadingRejection, setIsLoadingRejection] = useState(false);
    const [isCustom, setIsCustom] = useState(false);

    const { remainingBatchDocuments, removeDocumentsFromBatch, isBatchMultisignature, selectedBatchDocumentGuids } = useContext(BatchContext);

    const { t } = useTranslation();
    const navigate = useNavigate();

    // On Init
    useEffect(() => {
        const isCustom = localStorage.getItem(IS_CUSTOM) === "true";
        setIsCustom(isCustom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e) => {
        const { value } = e.target;
    
        setRejectionReason(value);
    
        if (value.length > 0) setIsEmptyRejectionReason(false);
    };

    const handleRejectDocument = async () => {
        if (!rejectionReason) {
          setIsEmptyRejectionReason(true);
          return;
        }
    
        setIsLoadingRejection(true);
    
        const evidenceData = await getEvidenceData();
    
        const body = {
          ...evidenceData,
          RejectionReason: rejectionReason,
        };
    
        RejectedSignatureService.rejectDocument(signerGuid, body)
          .then(() => {
            removeDocumentsFromBatch(isBatchMultisignature ? selectedBatchDocumentGuids : [docGuid]);
            const pendingBatchDocsLength = remainingBatchDocuments - (selectedBatchDocumentGuids.length ? selectedBatchDocumentGuids.length : 1);

            if (pendingBatchDocsLength > 0) {
              removeDocumentsFromBatch([docGuid]);
                navigate('/documents', { state: {
                    emailId,
                }})
                return;
            }
            PendingDocumentService.getPendingDocumentsList(emailId)
                .then(res => {
                    const pendingDocuments = res.data;
                    setIsLoadingRejection(false);
                    navigate("/rejected-success", {
                        state: {
                            pendingDocuments,
                            emailId,
                            email
                        }
                    });
                })
                .catch(err => {
                    setIsLoadingRejection(false);
                    navigate("/rejected-success", {
                        state: {
                            pendingDocuments: [],
                            emailId,
                            email
                        }
                    });
                });
          })
      };

    return (
        <Modal
            title={t("document_detail.modal1.header")}
            footer={null}
            open={openRejectModal}
            closable={true}
            onCancel={() => setOpenRejectModal(false)}
        >
            {isLoadingRejection ? (
                <div className="loader-container">
                    <img src={isCustom ? whiteLoaderGif : documentLoadingGif} alt="LoadingGif" />
                </div>
            ) : (
            <div className="modal-container">
                <p className="nu-input-label">
                {t("document_detail.modal1.description")}
                </p>
                <Form.Item>
                <Input
                    value={rejectionReason}
                    onChange={handleChange}
                    className="nu-input"
                    name="rejectionReason"
                    placeholder={t("document_detail.modal1.reject_reason")}
                />
                </Form.Item>
                {isEmptyRejectionReason && (
                    <p className="nu-input-label validation-error">
                        {t("document_detail.modal1.empty_reason_message")}
                    </p>
                )}
                <Row className="action-buttons mobile-button">
                    <Col md={6} xs={6}>
                        <button
                            type="button"
                            className="btn btn-secondary btn-cancel btn-mobile"
                            onClick={() => setOpenRejectModal(false)}
                        >
                            <span className="cancel-text">{t("general.cancel")}</span>
                            <CancelIcon />
                        </button>
                    </Col>
                    <Col md={6} xs={6}>
                        <button
                            type="button"
                            className="btn btn-primary btn-active"
                            onClick={handleRejectDocument}
                            style={{ display: 'flex', alignItems: 'center', gap: '3px' }}
                        >
                            <span className="cancel-text">{t("general.reject")}</span>
                            <CheckIcon />
                        </button>
                    </Col>
                </Row>
            </div>
            )}
        </Modal>
    )
}