import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { CheckIcon } from "../../SvgComponents/CheckIcon/CheckIcon";

export const DocumentPreviewDisclaimerModal = ({ showModalDisclaimer, setShowModalDisclaimer, title, message }) => {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                title={title}
                footer={null}
                open={showModalDisclaimer}
                closable={false}
            >
                <div className="modal-container disclaimer-modal">
                    <p className="nu-input-label">{message}</p>
                    <Col className="action-buttons mobile-button">
                        <Row>
                            <Col md={3} xs={3}></Col>
                            <Col md={6} xs={6}>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-active"
                                    onClick={() => setShowModalDisclaimer(false)}
                                >
                                    <span className="cancel-text">{t("general.ok")}</span>
                                    <CheckIcon />
                                </button>
                            </Col>
                            <Col md={3} xs={3}></Col>
                        </Row>
                    </Col>
                </div>
            </Modal>
        </>
    );
};
