import React, { useEffect, useState } from 'react';

export const EnterPhoneNumberIcon = ({ width, height }) => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-primary-background-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg width={width} height={height} viewBox="0 0 180 176" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M120.958 83.224C120.958 83.224 137.77 90.0508 140.275 92.4266C145.708 97.5773 147.164 110.45 147.164 117.558C147.164 121.423 148.426 125.71 149.3 128.213" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M132.493 100.798C128.767 96.5811 125.35 96.7147 122.574 94.0048C117.165 88.7223 117.676 89.1047 112.72 84.3066C109.775 81.4575 107.776 79.7832 104.824 81.7526C102.345 83.4082 102.608 85.5409 103.862 88.249C105.391 91.5492 113.504 102.11 115.208 104.583C115.576 105.112 115.794 105.73 115.841 106.372C116.093 109.865 117.029 119.766 130.432 123.467" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M117.101 126.181C120.86 127.896 122.128 133.13 122.128 133.13" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M69.1534 76.4932V50.2236C69.1534 48.5671 69.811 46.9785 70.9812 45.8072C72.1514 44.6358 73.7384 43.9778 75.3933 43.9778H108.634C109.454 43.9773 110.265 44.1385 111.023 44.4522C111.78 44.7659 112.468 45.2259 113.048 45.806C113.628 46.386 114.088 47.0747 114.401 47.8327C114.715 48.5907 114.876 49.4032 114.876 50.2236V76.6621" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M114.876 121.15V123.332C114.876 124.989 114.218 126.578 113.048 127.749C111.878 128.92 110.291 129.578 108.636 129.578H97.3337" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M114.874 55.1013H69.1534" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <g opacity="0.3">
          <path d="M4.35769 118.736H1.39075C1.0219 118.736 0.668082 118.59 0.407265 118.328C0.146449 118.067 0 117.713 0 117.344C0 116.975 0.146449 116.621 0.407265 116.36C0.668082 116.099 1.0219 115.952 1.39075 115.952H4.35769C4.72653 115.952 5.08035 116.099 5.34117 116.36C5.60199 116.621 5.74844 116.975 5.74844 117.344C5.74844 117.713 5.60199 118.067 5.34117 118.328C5.08035 118.59 4.72653 118.736 4.35769 118.736Z" fill={color} />
          <path d="M15.8542 118.736H12.5164C12.1475 118.736 11.7937 118.59 11.5329 118.328C11.2721 118.067 11.1256 117.713 11.1256 117.344C11.1256 116.975 11.2721 116.621 11.5329 116.36C11.7937 116.099 12.1475 115.952 12.5164 115.952H15.8542C16.223 115.952 16.5768 116.099 16.8376 116.36C17.0985 116.621 17.2449 116.975 17.2449 117.344C17.2449 117.713 17.0985 118.067 16.8376 118.328C16.5768 118.59 16.223 118.736 15.8542 118.736Z" fill={color} />
          <path d="M8.52979 126.068C8.16094 126.068 7.80712 125.921 7.5463 125.66C7.28549 125.399 7.13904 125.045 7.13904 124.676V121.706C7.13904 121.337 7.28549 120.983 7.5463 120.722C7.80712 120.46 8.16094 120.314 8.52979 120.314C8.89864 120.314 9.25246 120.46 9.51327 120.722C9.77409 120.983 9.92054 121.337 9.92054 121.706V124.676C9.92054 125.045 9.77409 125.399 9.51327 125.66C9.25246 125.921 8.89864 126.068 8.52979 126.068Z" fill={color} />
          <path d="M8.52979 114.931C8.16094 114.931 7.80712 114.785 7.5463 114.524C7.28549 114.262 7.13904 113.908 7.13904 113.539V110.198C7.13904 109.829 7.28549 109.475 7.5463 109.214C7.80712 108.953 8.16094 108.806 8.52979 108.806C8.89864 108.806 9.25246 108.953 9.51327 109.214C9.77409 109.475 9.92054 109.829 9.92054 110.198V113.539C9.92054 113.908 9.77409 114.262 9.51327 114.524C9.25246 114.785 8.89864 114.931 8.52979 114.931Z" fill={color} />
        </g>
        <g opacity="0.3">
          <path d="M121.737 39.2951H118.77C118.401 39.2951 118.047 39.1484 117.786 38.8874C117.526 38.6263 117.379 38.2722 117.379 37.903C117.379 37.5338 117.526 37.1797 117.786 36.9187C118.047 36.6576 118.401 36.5109 118.77 36.5109H121.737C122.106 36.5109 122.46 36.6576 122.72 36.9187C122.981 37.1797 123.128 37.5338 123.128 37.903C123.128 38.2722 122.981 38.6263 122.72 38.8874C122.46 39.1484 122.106 39.2951 121.737 39.2951Z" fill={color} />
          <path d="M133.233 39.2951H129.896C129.527 39.2951 129.173 39.1484 128.912 38.8874C128.651 38.6263 128.505 38.2722 128.505 37.903C128.505 37.5338 128.651 37.1797 128.912 36.9187C129.173 36.6576 129.527 36.5109 129.896 36.5109H133.233C133.602 36.5109 133.956 36.6576 134.217 36.9187C134.478 37.1797 134.624 37.5338 134.624 37.903C134.624 38.2722 134.478 38.6263 134.217 38.8874C133.956 39.1484 133.602 39.2951 133.233 39.2951Z" fill={color} />
          <path d="M125.909 46.6266C125.54 46.6266 125.186 46.48 124.925 46.2189C124.665 45.9578 124.518 45.6038 124.518 45.2346V42.2648C124.518 41.8956 124.665 41.5415 124.925 41.2804C125.186 41.0193 125.54 40.8727 125.909 40.8727C126.278 40.8727 126.632 41.0193 126.892 41.2804C127.153 41.5415 127.3 41.8956 127.3 42.2648V45.2346C127.3 45.6038 127.153 45.9578 126.892 46.2189C126.632 46.48 126.278 46.6266 125.909 46.6266Z" fill={color} />
          <path d="M125.909 35.4902C125.54 35.4902 125.186 35.3435 124.925 35.0824C124.665 34.8214 124.518 34.4673 124.518 34.0981V30.7571C124.518 30.3879 124.665 30.0338 124.925 29.7727C125.186 29.5117 125.54 29.365 125.909 29.365C126.278 29.365 126.632 29.5117 126.892 29.7727C127.153 30.0338 127.3 30.3879 127.3 30.7571V34.0981C127.3 34.4673 127.153 34.8214 126.892 35.0824C126.632 35.3435 126.278 35.4902 125.909 35.4902Z" fill={color} />
        </g>
        <path opacity="0.3" d="M24.95 150.847C24.0584 150.847 23.1869 150.582 22.4455 150.086C21.7042 149.59 21.1265 148.886 20.7853 148.061C20.4441 147.237 20.3548 146.329 20.5287 145.454C20.7027 144.579 21.132 143.775 21.7625 143.144C22.3929 142.513 23.1962 142.083 24.0706 141.909C24.9451 141.735 25.8515 141.824 26.6752 142.166C27.4989 142.507 28.2028 143.086 28.6981 143.828C29.1935 144.57 29.458 145.442 29.458 146.334C29.457 147.531 28.9818 148.678 28.1366 149.524C27.2914 150.37 26.1452 150.846 24.95 150.847ZM24.95 144.606C24.6085 144.606 24.2748 144.708 23.9909 144.898C23.707 145.088 23.4856 145.357 23.355 145.673C23.2243 145.989 23.1902 146.336 23.2568 146.672C23.3234 147.007 23.4878 147.315 23.7293 147.556C23.9707 147.798 24.2783 147.963 24.6132 148.029C24.948 148.096 25.2951 148.062 25.6106 147.931C25.926 147.8 26.1958 147.579 26.3855 147.295C26.5752 147.01 26.6765 146.676 26.6765 146.334C26.6767 146.107 26.6322 145.882 26.5455 145.673C26.4589 145.463 26.3318 145.272 26.1714 145.111C26.0111 144.951 25.8207 144.823 25.6111 144.736C25.4016 144.649 25.1769 144.605 24.95 144.605V144.606Z" fill={color} />
        <path opacity="0.3" d="M173.287 63.5417C172.396 63.5417 171.524 63.277 170.783 62.7812C170.042 62.2854 169.464 61.5807 169.122 60.7562C168.781 59.9317 168.692 59.0244 168.866 58.1491C169.04 57.2739 169.469 56.4699 170.1 55.8388C170.73 55.2078 171.534 54.778 172.408 54.6039C173.282 54.4298 174.189 54.5192 175.012 54.8607C175.836 55.2022 176.54 55.7805 177.035 56.5226C177.531 57.2646 177.795 58.137 177.795 59.0294C177.794 60.2257 177.318 61.3725 176.473 62.2184C175.628 63.0643 174.482 63.5402 173.287 63.5417ZM173.287 57.3014C172.946 57.3014 172.612 57.4027 172.328 57.5926C172.044 57.7825 171.823 58.0524 171.692 58.3681C171.562 58.6839 171.528 59.0313 171.594 59.3666C171.661 59.7018 171.825 60.0097 172.067 60.2513C172.308 60.493 172.616 60.6576 172.95 60.7243C173.285 60.7909 173.632 60.7567 173.948 60.6259C174.263 60.4951 174.533 60.2737 174.723 59.9895C174.912 59.7053 175.014 59.3712 175.014 59.0294C175.013 58.5711 174.831 58.1317 174.508 57.8074C174.184 57.4831 173.745 57.3005 173.287 57.2995V57.3014Z" fill={color} />
        <path opacity="0.3" d="M37.9217 56.8581C37.0301 56.8581 36.1586 56.5935 35.4173 56.0977C34.676 55.6019 34.0981 54.8972 33.7569 54.0727C33.4157 53.2482 33.3265 52.3409 33.5005 51.4656C33.6744 50.5903 34.1038 49.7863 34.7342 49.1553C35.3647 48.5242 36.1679 48.0945 37.0424 47.9204C37.9168 47.7463 38.823 47.8356 39.6467 48.1772C40.4705 48.5187 41.1745 49.097 41.6699 49.839C42.1652 50.5811 42.4295 51.4535 42.4295 52.3459C42.428 53.5422 41.9527 54.689 41.1076 55.5349C40.2625 56.3808 39.1168 56.8566 37.9217 56.8581ZM37.9217 50.6179C37.5803 50.6179 37.2466 50.7192 36.9627 50.9091C36.6788 51.099 36.4574 51.3688 36.3267 51.6846C36.196 52.0004 36.1619 52.3478 36.2285 52.683C36.2952 53.0182 36.4596 53.3261 36.701 53.5678C36.9424 53.8095 37.25 53.9741 37.5849 54.0407C37.9198 54.1074 38.2669 54.0732 38.5823 53.9424C38.8978 53.8116 39.1675 53.5901 39.3572 53.306C39.5469 53.0218 39.648 52.6877 39.648 52.3459C39.6475 51.8876 39.4657 51.4481 39.1421 51.1239C38.8185 50.7996 38.3796 50.617 37.9217 50.616V50.6179Z" fill={color} />
        <g opacity="0.3">
          <path d="M133.814 13.7051C136.697 15.3318 139.483 17.1241 142.158 19.073C142.306 19.1806 142.474 19.2581 142.651 19.3009C142.829 19.3438 143.013 19.3512 143.193 19.3227C143.374 19.2943 143.547 19.2306 143.703 19.1352C143.859 19.0398 143.994 18.9146 144.102 18.7667C144.209 18.6189 144.287 18.4513 144.329 18.2736C144.372 18.0958 144.38 17.9113 144.351 17.7307C144.323 17.5501 144.259 17.3768 144.164 17.2208C144.069 17.0648 143.943 16.9291 143.796 16.8215C141.034 14.8097 138.159 12.9592 135.184 11.2792C135.025 11.1884 134.85 11.13 134.668 11.1073C134.486 11.0847 134.301 11.0982 134.125 11.1471C133.948 11.1961 133.783 11.2794 133.639 11.3924C133.494 11.5055 133.374 11.6459 133.284 11.8057C133.194 11.9655 133.137 12.1415 133.115 12.3236C133.093 12.5057 133.108 12.6903 133.157 12.8668C133.207 13.0433 133.291 13.2082 133.405 13.3521C133.518 13.4959 133.659 13.6159 133.82 13.7051H133.814Z" fill={color} />
          <path d="M112.71 2.43521C97.2654 -1.26587 81.1108 -0.740158 65.9394 3.95723C65.761 4.00797 65.5945 4.09393 65.4497 4.21005C65.305 4.32617 65.185 4.4701 65.0966 4.63338C65.0083 4.79665 64.9535 4.97597 64.9355 5.16077C64.9174 5.34557 64.9364 5.53212 64.9914 5.70944C65.0465 5.88675 65.1365 6.05126 65.256 6.19327C65.3755 6.33527 65.5221 6.45191 65.6873 6.5363C65.8526 6.6207 66.0331 6.67116 66.2181 6.68471C66.4031 6.69826 66.5889 6.67462 66.7646 6.61519C81.4579 2.06629 97.1031 1.55726 112.061 5.14143C112.419 5.22758 112.798 5.16754 113.112 4.97452C113.427 4.78151 113.652 4.47133 113.738 4.11222C113.824 3.7531 113.764 3.37448 113.571 3.05963C113.378 2.74479 113.068 2.5195 112.71 2.43335V2.43521Z" fill={color} />
          <path d="M37.9637 20.4059C37.8474 20.2648 37.7044 20.1481 37.543 20.0624C37.3816 19.9766 37.2048 19.9236 37.0229 19.9064C36.841 19.8892 36.6575 19.908 36.4829 19.9619C36.3083 20.0157 36.146 20.1036 36.0054 20.2203C34.5497 21.4268 33.1145 22.6982 31.7404 23.9994C31.6076 24.1249 31.5008 24.2754 31.426 24.4423C31.3513 24.6091 31.3101 24.7891 31.3049 24.9719C31.2996 25.1547 31.3304 25.3367 31.3954 25.5076C31.4605 25.6785 31.5585 25.8348 31.6839 25.9678C31.8093 26.1008 31.9596 26.2077 32.1263 26.2825C32.293 26.3573 32.4728 26.3985 32.6554 26.4038C32.8381 26.409 33.0198 26.3782 33.1906 26.3132C33.3613 26.2481 33.5175 26.1499 33.6503 26.0244C34.9836 24.7641 36.3744 23.5317 37.7837 22.3623C38.066 22.1263 38.2436 21.788 38.2773 21.4213C38.311 21.0547 38.1982 20.6896 37.9637 20.4059Z" fill={color} />
          <path d="M17.4488 44.1293C17.2899 44.0391 17.1149 43.981 16.9336 43.9586C16.7523 43.9361 16.5685 43.9497 16.3925 43.9985C16.2165 44.0473 16.0516 44.1303 15.9077 44.2429C15.7638 44.3555 15.6435 44.4954 15.5537 44.6546C9.91429 54.586 6.28202 65.5308 4.86358 76.8657C3.93024 84.2781 3.93024 91.7786 4.86358 99.191C4.90598 99.527 5.06921 99.836 5.32282 100.06C5.57643 100.284 5.90298 100.408 6.24129 100.409C6.30328 100.409 6.36522 100.405 6.42673 100.397C6.608 100.375 6.7829 100.316 6.94156 100.225C7.10021 100.135 7.23956 100.014 7.35136 99.8688C7.46315 99.7242 7.54525 99.5588 7.59311 99.3823C7.64096 99.2058 7.65363 99.0216 7.63023 98.8402C6.72656 91.6601 6.72656 84.3948 7.63023 77.2146C9.00469 66.2416 12.5216 55.6463 17.981 46.0319C18.0718 45.8722 18.1301 45.6961 18.1525 45.5137C18.1749 45.3314 18.1609 45.1464 18.1114 44.9695C18.0619 44.7926 17.9777 44.6273 17.864 44.4831C17.7503 44.3388 17.6092 44.2186 17.4488 44.1293Z" fill={color} />
          <path d="M49.5624 161.846C46.2826 159.948 43.1319 157.834 40.1312 155.518C39.8391 155.293 39.4694 155.193 39.1036 155.24C38.7377 155.288 38.4058 155.478 38.1806 155.771C37.9553 156.063 37.8553 156.433 37.9026 156.799C37.9499 157.166 38.1405 157.498 38.4326 157.724C41.5311 160.114 44.7846 162.297 48.1717 164.257C48.4914 164.442 48.8712 164.492 49.2276 164.396C49.5841 164.3 49.888 164.067 50.0724 163.747C50.2568 163.427 50.3067 163.046 50.2111 162.69C50.1155 162.333 49.8821 162.029 49.5624 161.844V161.846Z" fill={color} />
          <path d="M110.294 171.263C96.877 174.176 82.9581 173.826 69.7043 170.243C69.5264 170.189 69.3394 170.171 69.1546 170.191C68.9697 170.211 68.7907 170.268 68.6281 170.358C68.4656 170.448 68.3227 170.57 68.208 170.716C68.0933 170.863 68.0092 171.031 67.9605 171.21C67.9117 171.39 67.8995 171.578 67.9244 171.762C67.9493 171.946 68.0109 172.124 68.1055 172.284C68.2001 172.444 68.3259 172.584 68.4753 172.694C68.6247 172.805 68.7948 172.885 68.9755 172.928C82.6594 176.63 97.0309 176.992 110.883 173.983C111.066 173.948 111.241 173.878 111.396 173.775C111.551 173.672 111.684 173.539 111.787 173.384C111.89 173.229 111.961 173.054 111.995 172.871C112.029 172.688 112.027 172.5 111.987 172.318C111.948 172.136 111.872 171.964 111.765 171.811C111.657 171.659 111.52 171.53 111.362 171.432C111.204 171.333 111.028 171.268 110.844 171.239C110.66 171.21 110.473 171.218 110.292 171.263H110.294Z" fill={color} />
          <path d="M139.922 158.501C137.949 159.847 135.892 161.124 133.814 162.297C133.494 162.479 133.26 162.78 133.162 163.135C133.064 163.49 133.11 163.869 133.29 164.189C133.471 164.51 133.771 164.746 134.125 164.846C134.478 164.946 134.857 164.902 135.179 164.723C137.326 163.511 139.444 162.193 141.483 160.802C141.786 160.594 141.994 160.274 142.062 159.912C142.129 159.55 142.051 159.177 141.844 158.873C141.638 158.568 141.319 158.359 140.958 158.289C140.597 158.219 140.223 158.296 139.918 158.501H139.922Z" fill={color} />
          <path d="M176.508 107.107C176.149 107.021 175.771 107.08 175.456 107.272C175.141 107.464 174.915 107.773 174.828 108.132C173.338 114.271 171.167 120.223 168.354 125.878C168.823 127.38 169.061 128.945 169.061 130.519C172.84 123.684 175.688 116.374 177.531 108.783C177.617 108.425 177.557 108.048 177.365 107.734C177.174 107.42 176.865 107.194 176.508 107.107Z" fill={color} />
          <path d="M156.279 147.464C156.468 147.464 156.655 147.425 156.829 147.35C157.002 147.275 157.158 147.165 157.288 147.027C156.936 147.183 156.578 147.328 156.218 147.462L156.279 147.464Z" fill={color} />
          <path d="M179.646 80.0632C179.46 77.9156 179.18 75.7533 178.834 73.6354C178.769 73.2758 178.565 72.9561 178.267 72.7453C177.969 72.5345 177.6 72.4495 177.24 72.5088C176.879 72.568 176.557 72.7666 176.342 73.0618C176.126 73.3569 176.036 73.725 176.089 74.0865C176.425 76.1282 176.69 78.2312 176.875 80.3119C177.103 82.8492 177.219 85.4366 177.219 87.9999C177.219 88.3691 177.365 88.7232 177.626 88.9843C177.887 89.2453 178.24 89.392 178.609 89.392C178.978 89.392 179.332 89.2453 179.593 88.9843C179.853 88.7232 180 88.3691 180 87.9999C180 85.3549 179.881 82.684 179.646 80.0632Z" fill={color} />
        </g>
        <path d="M77.448 72.2117H106.598" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1 6" />
        <path d="M57.1113 109.196L55.166 111.484L57.1149 113.775C63.3028 121.041 74.8664 121.041 81.0524 113.775L83.0033 111.484L81.0524 109.196C74.8627 101.929 63.2992 101.929 57.1113 109.196Z" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M69.0812 114.58C70.7894 114.58 72.1742 113.194 72.1742 111.484C72.1742 109.774 70.7894 108.388 69.0812 108.388C67.373 108.388 65.9882 109.774 65.9882 111.484C65.9882 113.194 67.373 114.58 69.0812 114.58Z" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M69.1521 139.459C84.6163 139.459 97.1525 126.91 97.1525 111.431C97.1525 95.9521 84.6163 83.4038 69.1521 83.4038C53.6878 83.4038 41.1516 95.9521 41.1516 111.431C41.1516 126.91 53.6878 139.459 69.1521 139.459Z" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </>
  );
}