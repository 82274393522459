import React, { useEffect, useState } from 'react';

export const SmsSendingIcon = ({ width, height }) => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-primary-background-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg width={width} height={height} viewBox="0 0 150 147" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M95.3899 69.5103C95.3899 69.5103 109.399 75.2121 111.487 77.1964C116.015 81.4984 117.228 92.2494 117.228 98.1869C117.228 101.415 118.28 104.996 119.008 107.085" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M105.002 84.1884C101.897 80.6663 99.0492 80.7779 96.7359 78.5145C92.2283 74.1025 92.6549 74.4218 88.5244 70.4144C86.0705 68.0348 84.4046 66.6364 81.9446 68.2813C79.8785 69.6641 80.098 71.4453 81.1426 73.7072C82.4174 76.4635 89.178 85.2845 90.5981 87.3494C90.9042 87.7911 91.0863 88.3073 91.1251 88.8439C91.3352 91.7614 92.1156 100.031 103.285 103.122" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M92.1758 105.388C95.3081 106.821 96.365 111.192 96.365 111.192" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M90.3213 64.0289V41.9471C90.3215 41.262 90.1872 40.5835 89.926 39.9505C89.6647 39.3175 89.2817 38.7424 88.7988 38.2579C88.3159 37.7735 87.7426 37.3892 87.1116 37.1271C86.4807 36.8651 85.8044 36.7303 85.1215 36.7305H57.4193C56.0402 36.7305 54.7176 37.2801 53.7424 38.2584C52.7673 39.2367 52.2195 40.5636 52.2195 41.9471V65.4381" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M52.2195 102.63V103.008C52.2199 104.392 52.7678 105.718 53.7429 106.696C54.718 107.675 56.0404 108.224 57.4193 108.225H85.1199C86.499 108.225 87.8217 107.675 88.7968 106.697C89.772 105.719 90.3198 104.392 90.3198 103.008V101.185" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M90.3198 46.021H52.2195" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M60.2395 58.75H83.4372" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <g opacity="0.3">
          <path d="M3.6314 99.1696H1.15896C0.851583 99.1696 0.55681 99.0471 0.339463 98.8291C0.122116 98.611 0 98.3153 0 98.0069C0 97.6986 0.122116 97.4028 0.339463 97.1848C0.55681 96.9667 0.851583 96.8442 1.15896 96.8442H3.6314C3.93877 96.8442 4.23355 96.9667 4.45089 97.1848C4.66824 97.4028 4.79036 97.6986 4.79036 98.0069C4.79036 98.3153 4.66824 98.611 4.45089 98.8291C4.23355 99.0471 3.93877 99.1696 3.6314 99.1696Z" fill={color} />
          <path d="M13.2121 99.1696H10.4306C10.1233 99.1696 9.82848 99.0471 9.61113 98.8291C9.39378 98.611 9.27167 98.3153 9.27167 98.0069C9.27167 97.6986 9.39378 97.4028 9.61113 97.1848C9.82848 96.9667 10.1233 96.8442 10.4306 96.8442H13.2121C13.5195 96.8442 13.8143 96.9667 14.0316 97.1848C14.249 97.4028 14.3711 97.6986 14.3711 98.0069C14.3711 98.3153 14.249 98.611 14.0316 98.8291C13.8143 99.0471 13.5195 99.1696 13.2121 99.1696Z" fill={color} />
          <path d="M7.10827 105.294C6.80089 105.294 6.50612 105.171 6.28877 104.953C6.07143 104.735 5.94931 104.439 5.94931 104.131V101.65C5.94931 101.342 6.07143 101.046 6.28877 100.828C6.50612 100.61 6.80089 100.488 7.10827 100.488C7.41564 100.488 7.71042 100.61 7.92776 100.828C8.14511 101.046 8.26723 101.342 8.26723 101.65V104.131C8.26723 104.439 8.14511 104.735 7.92776 104.953C7.71042 105.171 7.41564 105.294 7.10827 105.294Z" fill={color} />
          <path d="M7.10827 95.9918C6.80089 95.9918 6.50612 95.8693 6.28877 95.6513C6.07143 95.4332 5.94931 95.1375 5.94931 94.8291V92.0387C5.94931 91.7303 6.07143 91.4346 6.28877 91.2165C6.50612 90.9985 6.80089 90.876 7.10827 90.876C7.41564 90.876 7.71042 90.9985 7.92776 91.2165C8.14511 91.4346 8.26723 91.7303 8.26723 92.0387V94.8291C8.26723 95.1375 8.14511 95.4332 7.92776 95.6513C7.71042 95.8693 7.41564 95.9918 7.10827 95.9918Z" fill={color} />
        </g>
        <g opacity="0.3">
          <path d="M101.447 32.8185H98.9749C98.6675 32.8185 98.3727 32.696 98.1554 32.478C97.938 32.26 97.8159 31.9642 97.8159 31.6559C97.8159 31.3475 97.938 31.0518 98.1554 30.8337C98.3727 30.6157 98.6675 30.4932 98.9749 30.4932H101.447C101.755 30.4932 102.049 30.6157 102.267 30.8337C102.484 31.0518 102.606 31.3475 102.606 31.6559C102.606 31.9642 102.484 32.26 102.267 32.478C102.049 32.696 101.755 32.8185 101.447 32.8185Z" fill={color} />
          <path d="M111.028 32.8185H108.247C107.939 32.8185 107.644 32.696 107.427 32.478C107.21 32.26 107.088 31.9642 107.088 31.6559C107.088 31.3475 107.21 31.0518 107.427 30.8337C107.644 30.6157 107.939 30.4932 108.247 30.4932H111.028C111.335 30.4932 111.63 30.6157 111.848 30.8337C112.065 31.0518 112.187 31.3475 112.187 31.6559C112.187 31.9642 112.065 32.26 111.848 32.478C111.63 32.696 111.335 32.8185 111.028 32.8185Z" fill={color} />
          <path d="M104.924 38.9425C104.617 38.9425 104.322 38.82 104.105 38.602C103.887 38.3839 103.765 38.0882 103.765 37.7798V35.2994C103.765 34.991 103.887 34.6953 104.105 34.4773C104.322 34.2592 104.617 34.1367 104.924 34.1367C105.232 34.1367 105.526 34.2592 105.744 34.4773C105.961 34.6953 106.083 34.991 106.083 35.2994V37.7798C106.083 38.0882 105.961 38.3839 105.744 38.602C105.526 38.82 105.232 38.9425 104.924 38.9425Z" fill={color} />
          <path d="M104.924 29.6407C104.617 29.6407 104.322 29.5182 104.105 29.3002C103.887 29.0822 103.765 28.7864 103.765 28.4781V25.6876C103.765 25.3792 103.887 25.0835 104.105 24.8654C104.322 24.6474 104.617 24.5249 104.924 24.5249C105.232 24.5249 105.526 24.6474 105.744 24.8654C105.961 25.0835 106.083 25.3792 106.083 25.6876V28.4781C106.083 28.7864 105.961 29.0822 105.744 29.3002C105.526 29.5182 105.232 29.6407 104.924 29.6407Z" fill={color} />
        </g>
        <path opacity="0.3" d="M20.7917 125.989C20.0487 125.989 19.3224 125.768 18.7047 125.354C18.0869 124.94 17.6054 124.352 17.3211 123.663C17.0368 122.974 16.9624 122.217 17.1073 121.486C17.2523 120.755 17.6101 120.083 18.1355 119.556C18.6608 119.029 19.3301 118.67 20.0588 118.525C20.7875 118.379 21.5429 118.454 22.2293 118.739C22.9157 119.024 23.5025 119.507 23.9152 120.127C24.328 120.747 24.5483 121.475 24.5483 122.221C24.5475 123.22 24.1514 124.178 23.4471 124.885C22.7428 125.591 21.7878 125.989 20.7917 125.989ZM20.7917 120.778C20.5072 120.778 20.229 120.862 19.9924 121.021C19.7559 121.179 19.5715 121.405 19.4626 121.668C19.3537 121.932 19.3252 122.222 19.3807 122.502C19.4363 122.782 19.5733 123.04 19.7745 123.241C19.9757 123.443 20.232 123.581 20.5111 123.636C20.7902 123.692 21.0794 123.663 21.3423 123.554C21.6052 123.445 21.8299 123.26 21.9879 123.023C22.146 122.785 22.2304 122.506 22.2304 122.221C22.2306 122.031 22.1936 121.843 22.1213 121.668C22.0491 121.493 21.9432 121.333 21.8096 121.199C21.676 121.065 21.5173 120.959 21.3427 120.886C21.168 120.813 20.9808 120.776 20.7917 120.776V120.778Z" fill={color} />
        <path opacity="0.3" d="M144.406 53.0701C143.663 53.0701 142.937 52.849 142.319 52.4349C141.701 52.0208 141.22 51.4322 140.935 50.7436C140.651 50.055 140.577 49.2972 140.722 48.5662C140.867 47.8351 141.224 47.1636 141.75 46.6365C142.275 46.1095 142.944 45.7505 143.673 45.6051C144.402 45.4597 145.157 45.5343 145.844 45.8196C146.53 46.1048 147.117 46.5879 147.529 47.2076C147.942 47.8274 148.163 48.556 148.163 49.3014C148.161 50.3005 147.765 51.2584 147.061 51.9649C146.357 52.6714 145.402 53.0688 144.406 53.0701ZM144.406 47.8581C144.121 47.8581 143.843 47.9428 143.607 48.1013C143.37 48.2599 143.186 48.4853 143.077 48.7491C142.968 49.0128 142.939 49.303 142.995 49.583C143.05 49.8629 143.188 50.1201 143.389 50.3219C143.59 50.5238 143.846 50.6613 144.125 50.7169C144.404 50.7726 144.694 50.7441 144.957 50.6348C145.219 50.5256 145.444 50.3406 145.602 50.1032C145.76 49.8659 145.845 49.5868 145.845 49.3014C145.844 48.9186 145.693 48.5516 145.423 48.2807C145.153 48.0099 144.788 47.8574 144.406 47.8566V47.8581Z" fill={color} />
        <path opacity="0.3" d="M31.6008 47.489C30.8578 47.489 30.1315 47.268 29.5138 46.8539C28.896 46.4398 28.4145 45.8512 28.1302 45.1625C27.8459 44.4739 27.7715 43.7162 27.9164 42.9851C28.0614 42.2541 28.4191 41.5825 28.9445 41.0555C29.4698 40.5284 30.1392 40.1695 30.8679 40.0241C31.5966 39.8787 32.352 39.9533 33.0384 40.2385C33.7248 40.5238 34.3115 41.0068 34.7242 41.6266C35.137 42.2463 35.3574 42.975 35.3574 43.7203C35.3561 44.7195 34.96 45.6773 34.2558 46.3838C33.5515 47.0903 32.5967 47.4878 31.6008 47.489ZM31.6008 42.277C31.3163 42.277 31.0381 42.3617 30.8015 42.5203C30.5649 42.6789 30.3805 42.9043 30.2716 43.168C30.1627 43.4317 30.1342 43.7219 30.1897 44.0019C30.2453 44.2819 30.3823 44.539 30.5835 44.7409C30.7847 44.9427 31.041 45.0802 31.3201 45.1359C31.5992 45.1916 31.8885 45.163 32.1514 45.0538C32.4143 44.9445 32.6389 44.7595 32.797 44.5222C32.9551 44.2848 33.0395 44.0058 33.0395 43.7203C33.039 43.3375 32.8874 42.9705 32.6177 42.6997C32.3481 42.4289 31.9824 42.2763 31.6008 42.2755V42.277Z" fill={color} />
        <g opacity="0.3">
          <path d="M111.512 11.4472C113.914 12.8058 116.236 14.3028 118.466 15.9305C118.714 16.112 119.024 16.1871 119.328 16.1391C119.632 16.0911 119.904 15.9241 120.085 15.6747C120.266 15.4253 120.341 15.1141 120.293 14.8094C120.245 14.5047 120.079 14.2316 119.83 14.05C117.529 12.3697 115.133 10.8242 112.654 9.42097C112.521 9.34516 112.375 9.29639 112.223 9.27746C112.072 9.25854 111.918 9.26983 111.771 9.3107C111.624 9.35156 111.486 9.42119 111.366 9.51559C111.246 9.60998 111.145 9.72728 111.07 9.86074C110.995 9.99421 110.947 10.1412 110.929 10.2933C110.911 10.4454 110.923 10.5996 110.965 10.747C111.006 10.8944 111.076 11.0321 111.171 11.1523C111.266 11.2725 111.383 11.3727 111.516 11.4472H111.512Z" fill={color} />
          <path d="M93.925 2.03393C81.0549 -1.05727 67.5929 -0.618192 54.9501 3.30514C54.8014 3.34752 54.6626 3.41932 54.542 3.5163C54.4213 3.61329 54.3213 3.7335 54.2477 3.86987C54.1741 4.00624 54.1284 4.15601 54.1133 4.31035C54.0983 4.4647 54.1142 4.62051 54.16 4.76861C54.2059 4.91671 54.2808 5.05411 54.3804 5.17271C54.48 5.29132 54.6023 5.38873 54.74 5.45922C54.8777 5.52971 55.028 5.57186 55.1822 5.58317C55.3364 5.59449 55.4912 5.57474 55.6377 5.52511C67.8821 1.7258 80.9197 1.30065 93.3842 4.2942C93.5322 4.32983 93.6859 4.33585 93.8362 4.31193C93.9866 4.28802 94.1307 4.23462 94.2605 4.1548C94.3903 4.07498 94.5032 3.97029 94.5926 3.84671C94.6821 3.72314 94.7464 3.5831 94.7819 3.43459C94.8175 3.28607 94.8234 3.13199 94.7996 2.98115C94.7758 2.83031 94.7225 2.68566 94.643 2.55545C94.5634 2.42525 94.459 2.31203 94.3359 2.22229C94.2127 2.13254 94.0731 2.068 93.925 2.03237V2.03393Z" fill={color} />
          <path d="M31.6365 17.0435C31.5396 16.9256 31.4206 16.8281 31.286 16.7565C31.1515 16.6849 31.0043 16.6407 30.8527 16.6263C30.7011 16.6119 30.5482 16.6276 30.4026 16.6726C30.2571 16.7176 30.1219 16.791 30.0047 16.8885C28.7917 17.8961 27.5956 18.9581 26.4506 20.0448C26.227 20.2565 26.0965 20.5487 26.0876 20.857C26.0788 21.1653 26.1924 21.4646 26.4035 21.6888C26.6145 21.9131 26.9058 22.0441 27.2131 22.053C27.5204 22.0618 27.8187 21.9479 28.0422 21.7361C29.1533 20.6835 30.3122 19.6541 31.4866 18.6775C31.7219 18.4804 31.8698 18.1978 31.8979 17.8915C31.926 17.5853 31.832 17.2804 31.6365 17.0435Z" fill={color} />
          <path d="M14.5411 36.8575C14.4088 36.7821 14.2629 36.7336 14.1118 36.7148C13.9607 36.6961 13.8074 36.7074 13.6607 36.7482C13.514 36.7889 13.3768 36.8583 13.2569 36.9523C13.137 37.0464 13.0367 37.1632 12.9618 37.2962C8.26231 45.591 5.23534 54.7323 4.05331 64.1994C3.27552 70.3904 3.27552 76.6548 4.05331 82.8458C4.08864 83.1264 4.22474 83.3845 4.43608 83.5717C4.64742 83.7589 4.91955 83.8624 5.20148 83.8628C5.25313 83.8631 5.30475 83.8599 5.356 83.8535C5.50707 83.8344 5.65289 83.7855 5.7851 83.7097C5.91732 83.634 6.03329 83.5328 6.12645 83.412C6.21962 83.2911 6.28811 83.153 6.32799 83.0056C6.36787 82.8582 6.37834 82.7043 6.35885 82.5528C5.60579 76.5559 5.60579 70.4878 6.35885 64.4908C7.50423 55.326 10.4351 46.4766 14.9846 38.4465C15.0603 38.3131 15.1088 38.166 15.1274 38.0137C15.146 37.8614 15.1344 37.7069 15.0931 37.5592C15.0519 37.4114 14.9819 37.2733 14.8871 37.1529C14.7924 37.0324 14.6748 36.932 14.5411 36.8575Z" fill={color} />
          <path d="M41.3021 135.176C38.5689 133.591 35.9434 131.825 33.4429 129.891C33.1994 129.703 32.8914 129.619 32.5866 129.659C32.2817 129.699 32.005 129.858 31.8172 130.102C31.6295 130.346 31.5463 130.655 31.5857 130.961C31.6251 131.267 31.7839 131.545 32.0274 131.733C34.6094 133.73 37.3206 135.553 40.1432 137.19C40.4096 137.344 40.7261 137.386 41.0232 137.306C41.3202 137.226 41.5734 137.031 41.7271 136.764C41.8808 136.496 41.9224 136.179 41.8427 135.881C41.763 135.583 41.5685 135.329 41.3021 135.175V135.176Z" fill={color} />
          <path d="M91.9116 143.042C80.7309 145.475 69.1318 145.182 58.087 142.189C57.7937 142.118 57.4842 142.165 57.2245 142.319C56.9647 142.473 56.7751 142.723 56.696 143.015C56.6169 143.307 56.6545 143.618 56.8009 143.883C56.9472 144.148 57.1908 144.345 57.4797 144.433C68.883 147.524 80.8593 147.826 92.403 145.313C92.6954 145.24 92.9479 145.055 93.107 144.799C93.266 144.542 93.3191 144.233 93.255 143.938C93.1909 143.642 93.0146 143.383 92.7635 143.216C92.5124 143.049 92.2062 142.987 91.91 143.042H91.9116Z" fill={color} />
          <path d="M116.602 132.383C114.958 133.507 113.244 134.573 111.512 135.553C111.246 135.705 111.05 135.957 110.969 136.253C110.887 136.549 110.925 136.866 111.076 137.134C111.226 137.401 111.476 137.599 111.771 137.682C112.066 137.766 112.381 137.729 112.649 137.579C114.439 136.567 116.203 135.466 117.903 134.305C118.155 134.131 118.329 133.863 118.385 133.561C118.441 133.259 118.376 132.947 118.204 132.693C118.032 132.439 117.766 132.264 117.465 132.206C117.164 132.147 116.853 132.211 116.599 132.383H116.602Z" fill={color} />
          <path d="M147.09 89.4574C146.792 89.385 146.476 89.4344 146.214 89.5948C145.951 89.7552 145.763 90.0136 145.69 90.3131C144.449 95.4403 142.64 100.412 140.296 105.135C140.686 106.39 140.884 107.696 140.884 109.011C144.033 103.302 146.407 97.197 147.943 90.8573C148.015 90.5582 147.965 90.243 147.805 89.9806C147.645 89.7183 147.388 89.5301 147.09 89.4574Z" fill={color} />
          <path d="M130.233 123.163C130.39 123.163 130.546 123.131 130.691 123.068C130.835 123.006 130.966 122.914 131.074 122.799C130.78 122.929 130.482 123.05 130.182 123.162L130.233 123.163Z" fill={color} />
          <path d="M149.705 66.8703C149.55 65.0767 149.317 63.2706 149.028 61.5018C148.974 61.2014 148.804 60.9344 148.556 60.7583C148.307 60.5823 148 60.5113 147.7 60.5608C147.4 60.6102 147.131 60.7761 146.951 61.0227C146.772 61.2692 146.696 61.5766 146.741 61.8785C147.021 63.5838 147.242 65.3402 147.396 67.0781C147.586 69.1973 147.682 71.3583 147.682 73.4992C147.682 73.8076 147.804 74.1033 148.022 74.3214C148.239 74.5394 148.534 74.6619 148.841 74.6619C149.148 74.6619 149.443 74.5394 149.661 74.3214C149.878 74.1033 150 73.8076 150 73.4992C150 71.2901 149.901 69.0593 149.705 66.8703Z" fill={color} />
        </g>
        <path d="M41.1815 73.9721C41.4633 73.4988 41.8628 73.107 42.341 72.8352C42.8191 72.5635 43.3594 72.421 43.9089 72.4219H65.8225C66.2799 72.4219 66.7318 72.5212 67.1474 72.7128C67.5629 72.9045 67.9323 73.184 68.2301 73.5322C68.5279 73.8804 68.7471 74.2892 68.8727 74.7303C68.9983 75.1715 69.0274 75.6347 68.9579 76.0882L66.7621 90.408C66.6469 91.1594 66.2674 91.8446 65.6923 92.3397C65.1172 92.8347 64.3845 93.1069 63.6267 93.107H42.8118C42.2665 93.1071 41.7303 92.966 41.2553 92.6974C40.7802 92.4288 40.3822 92.0418 40.0999 91.5738" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M66.3496 75.5967L57.3112 83.3681C56.7366 83.8627 56.0046 84.1346 55.2475 84.1346C54.4904 84.1346 53.7584 83.8627 53.1838 83.3681L44.1455 75.5967" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M39.3875 86.7354H44.1454" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M33.0442 81.9619H40.973" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M34.6296 77.1875H37.8021" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M34.6296 86.7354H36.2151" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </>
  );
}