import { Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CancelIcon } from "../../SvgComponents/CancelIcon/CancelIcon";

export const ShowOtpByCallWarningModal = ({ openShowOtpByCallWarningModal, setOpenShowOtpByCallWarningModal }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t("document_warning.title")}
            footer={null}
            open={openShowOtpByCallWarningModal}
            closable={true}
            onCancel={() => setOpenShowOtpByCallWarningModal(false)}
            style={{ top: 200 }}
        >
            <div className="modal-container">
                <p className="nu-input-label">{t("sms.custom_modal_description_call")}</p>
                <Col className="action-buttons mobile-button">
                    <Row>
                        <Col md={3} xs={3}></Col>
                        <Col md={6} xs={6}>
                            <button
                                type="button"
                                className="btn btn-primary btn-active"
                                onClick={() => setOpenShowOtpByCallWarningModal(false)}
                            >
                                <span className="cancel-text">{t("general.ok")}</span>
                                <CancelIcon />
                            </button>
                        </Col>
                        <Col md={3} xs={3}></Col>
                    </Row>
                </Col>
            </div>
        </Modal>
    );
};