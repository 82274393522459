import React, { useContext, useEffect, useState } from 'react';
import "./SendCustomSms.css";
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { SMSConfirmationModal } from '../../components/Modals/SMSConfirmationModal/SMSConfirmationModal';
import EmailSignatureService from '../../services/EmailSignatureService';
import { Loader } from '../../components/Loader/Loader';
import { Back } from '../../components/Back/Back';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { EnterPhoneNumberIcon } from '../../components/SvgComponents/EnterPhoneNumberIcon/EnterPhoneNumberIcon';
import { isMobile } from 'react-device-detect';
import { BatchContext } from '../../contexts/BatchContext';

export const SendCustomSms = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [openModalPhoneNumberConfirmation, setOpenModalPhoneNumberConfirmation] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const documents = location.state.documents;
  const emailId = location.state.emailId;
  const [widthIcon, setWidthIcon] = useState(180);
  const [heightIcon, setHeightIcon] = useState(176);

  const { isBatchMultisignature } = useContext(BatchContext);

   // On Init
   useEffect(() => {
    if (isMobile) {
      setWidthIcon(120);
      setHeightIcon(115);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowCustomPhoneNumber = () => {
    setOpenModalPhoneNumberConfirmation(false);
    setLoading(true);
    const body = {
      "PhoneNumber": phoneNumber
    };

    EmailSignatureService.sendOTPSms(emailId, body)
      .then((response) => {
        setLoading(false);
        const data = response.data;
        let link = data.Channel.includes('SMS') ? "/sms" : "/call";
        navigate(link, {
          state: {
            isPhoneNumberCustom: true,
            phoneNumber,
            documents,
            emailId
          }
        });
      })
      .catch(err => {
        setShowErrorMessage(true);
        setLoading(false);
        console.log(err);
      });
  }

  const handleBack = () => {
    let link = '/document';
    if (documents.SignerDTO.Form && documents.SignerDTO.Form.FormDisplay.toLowerCase() === "onpreview") link = "/document-questions";

    if (isBatchMultisignature) link = '/documents';

    navigate(link, {
      state: {
        documents,
        emailId,
      }
    });
  }

  return (
    <>
      {loading && <Loader />}
      {!loading &&
        <Container className="document-preview-container">
          <Row>
            <Col md={12} lg={12} className="document-preview-title">
              <Back handleBack={handleBack} />
            </Col>
            <Col md={2} xs={1}></Col>
            <Col md={12} xs={10} lg={12} className="justify-content-center container-sms-box">
              <Row>
                <Col md={4} lg={4}>
                  <EnterPhoneNumberIcon width={widthIcon} height={heightIcon} />
                </Col>
                <Col md={8} lg={5} className="col-align">
                  <h4 className="sms-title">{t('sms.custom_title')}</h4><br></br>
                  <p className="sms-description">{t('sms.custom_description_customphonenumber')}</p>
                  <Form className="phone-number-form">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control className="phone-number-input" type="text" placeholder={t('sms.custom_phone_number')} value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                    </Form.Group>
                  </Form>
                  {
                    showErrorMessage &&
                    <div className="error-message align-error-message">
                      {t('sms.custom_error_sending_sms')} <br /> {t('sms.custom_phone_number')}
                    </div>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={0}></Col>
            <Col md={4} xs={12} className="button-margin button-only">
              <button type="button" className="btn btn-primary btn-active phone-number-button" disabled={phoneNumber.length === 0} onClick={() => setOpenModalPhoneNumberConfirmation(true)}>
                <span className="cancel-text"> {t('sms.custom_send')} </span>
                <ArrowIcon disabled={phoneNumber.length === 0}/>
              </button>
            </Col>
          </Row>
          <SMSConfirmationModal openModalPhoneNumberConfirmation={openModalPhoneNumberConfirmation} setOpenModalPhoneNumberConfirmation={setOpenModalPhoneNumberConfirmation} handleShowCustomPhoneNumber={handleShowCustomPhoneNumber} phoneNumber={phoneNumber}></SMSConfirmationModal>
        </Container>
      }
    </>
  );
}

