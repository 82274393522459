import { useTranslation } from "react-i18next";

export const AutofirmaMandatory = () => {
    const { t } = useTranslation();

    return (
        <div className="modal-container modal-autofirma">
            {t("sign.autofirma_signing_process")}
        </div>
    );
}