import { BackIcon } from '../SvgComponents/BackIcon/BackIcon';

export const Back = ({ handleBack }) => {
    return (
        <>
            <span onClick={handleBack} style={{ cursor: 'pointer' }}>
                <BackIcon />
            </span>
        </>
    );
};