import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "../SvgComponents/CheckIcon/CheckIcon";
import { CancelIcon } from "../SvgComponents/CancelIcon/CancelIcon";

export const AutofirmaOptional = ({ loadingAutofirma, setShowAutofirmaModal, startCorrectSignMethod, signDocAutofirma, setAutofirma  }) => {
    const { t } = useTranslation();

    if (loadingAutofirma) return (
        <div className="modal-container modal-autofirma">
            {t("sign.autofirma_signing_process")}
        </div>
    );

    return (
        <>
            <div className="modal-container">
                {t("sign.autofirma_description")}
            </div>
            <Row className="action-buttons mobile-button">
                <Col md={6} xs={6}>
                    <button
                        type="button"
                        className="btn btn-primary btn-cancel"
                        onClick={() => { setShowAutofirmaModal(false); setAutofirma(false); startCorrectSignMethod() }}
                    >
                        <span className="cancel-text">{t("general.no")}</span>
                        <CancelIcon />
                    </button>
                </Col>
                <Col md={6} xs={6}>
                    <button
                        type="button"
                        className="btn btn-primary btn-active"
                        onClick={() => signDocAutofirma()}
                    >
                        <span className="cancel-text">{t("general.yes")}</span>
                        <CheckIcon />
                    </button>
                </Col>
            </Row>
        </>
    );
}