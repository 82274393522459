import './SuccessSignatureHeader.css';

export const SuccessSignatureHeader = () => {
    const logoImage = window.getComputedStyle(document.documentElement).getPropertyValue('--custom-logo');

    const imageURL = logoImage?.split('"')?.[1];

    return (
        <div className="signature-success-header row">
            <img className='custom-logo-image' src={imageURL} alt="Logo" />
        </div>
    );
}