import React, { useEffect, useState } from 'react';

export const CheckIcon = () => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-primary-text-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5.77025L6.21997 10.5958L16.1628 1.4043" stroke={color} strokeWidth="2" />
      </svg>
    </>
  );
}