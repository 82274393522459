import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import "./SignatureSuccess.css";
import { ArrowIcon } from "../../components/SvgComponents/ArrowIcon/ArrowIcon";
import { SignatureCompletionImage } from "../../components/SvgComponents/SignatureCompletionImage/SignatureCompletionImage";
import { useContext, useEffect, useState } from "react";
import { URLReturnContext } from "../../contexts/URLReturnContext";
import { RedirectingModal } from "../../components/Modals/RedirectingModal/RedirectingModal";
import { SuccessSignatureHeader } from "../../components/SuccessSignatureHeader/SuccessSignatureHeader";
import { PoweredByValidatedID } from "../../components/PoweredByValidatedID/PoweredByValidatedID";

export const SignatureSuccess = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isRedirecting, setRedirecting] = useState(false);
  const { urlReturn } = useContext(URLReturnContext);

  const pendingDocuments = location.state.pendingDocuments;
  const emailId = location.state.emailId;
  const email = location.state.email;

  const hasPendingDocuments = pendingDocuments.length > 0;

  const goToPendingDocuments = () => {
    const lang = localStorage.getItem('i18nextLng');
    navigate("/pending-documents", {
      state: {
        pendingDocuments,
        emailId,
        email,
        lang
      }
    });
  };

  const handleRedirect = () => {
    setRedirecting(true);

    window.location.href = urlReturn;
  }

  useEffect(() => {
    if (urlReturn) {
      handleRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={2} xs={0}></Col>
          <Col md={8} xs={12} className="justify-content-center padding-content">
            <SuccessSignatureHeader />
            <Row className="container-box signature-success" style={{ display: "flex", flexDirection: "column" }}>
              <SignatureCompletionImage />
              <h2 className="rejected-title">{t("sign.sign_complete_title")}</h2>
              <p className="rejected-subtitle">{t("popup_process.subtitle")}</p>
              {hasPendingDocuments &&
                <>
                  <hr className="success-hr" />
                  <p>
                    {t("popup_process.pending_documents_1")}{" "}
                    <span className="green-text" onClick={goToPendingDocuments} style={{ cursor: 'pointer' }}>
                      {t("popup_process.pending_documents_2", { pending: pendingDocuments.length })}
                    </span>{" "}
                    {t("popup_process.pending_documents_3")}
                  </p>
                </>
              }
            </Row>
            {
              hasPendingDocuments &&
              <Row className="delegation-button action-buttons mobile-button">
                <Col md={1} lg={3} xs={1}></Col>
                <Col md={10} lg={6} xs={12}>
                  <button type="button" className="btn btn-primary btn-active" onClick={goToPendingDocuments}>
                    <span className="cancel-text"> {t("popup_process.next_document_button")} </span>
                    <ArrowIcon />
                  </button>
                </Col>
                <Col md={1} lg={3} xs={1}></Col>
              </Row>
            }
            <PoweredByValidatedID />
          </Col>
          <Col md={2} xs={0}></Col>
        </Row>
      </Container>
      <RedirectingModal isRedirecting={isRedirecting} />
    </>
  );
};
