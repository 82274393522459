import { useTranslation } from 'react-i18next';
import './PendingDocumentItem.css';
import { SimpleArrow } from '../SvgComponents/SimpleArrow/SimpleArrow';

export const PendingDocumentItem = ({ item, lang }) => {
    const { t } = useTranslation();
    const date = new Date(item.ExpirationDate);
    const day = date.getDate();
    const mouth = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day}/${mouth}/${year}`;

    // Get frontend URL dynamically
    const frontendURL = process.env.REACT_APP_BACKEND_URL;
    const frontendURLBase = frontendURL.split('/api')[0];
    let originalURL = item.AccessURL;
    let updatedURL = originalURL.replace('https://remote.vidsigner.net', frontendURLBase);

    const goToDocumentId = () => {
        const accessURL = updatedURL + '&isOpenedFromDocumentsList=true';
        window.top.location.href = accessURL;
    };

    return (
        <div className='document-container' onClick={() => goToDocumentId()}>
            <div className='document-info-container'>
                <h5>{item.FileName}</h5>
                <p> {t('general.expiration_date')} {formattedDate}</p>
            </div>
            <SimpleArrow />
        </div>
    );
}