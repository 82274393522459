import { useContext, useEffect, useRef } from "react";
import "./TextBox.css";
import { FormContext } from "../../contexts/FormContext";
import Helper from "../../util/helper";

export const TextBox = ({ isAdvancedForm, componentData, onUpdateValue }) => {
  const { isHTML, checkDisabled, getScreenDimensions } = useContext(FormContext);
  const isDisabled = checkDisabled(componentData, isAdvancedForm);
  let hasError = componentData.error;
  const hasMaxlines = componentData.maxlines > 1;
  const hasMaxLength = !!componentData.maxlength;

  let inputValue = '';

  if (isAdvancedForm) {
    if (isHTML) {
      inputValue = componentData.response.text.split(">")[1]?.split("<")[0];
    }
    inputValue = componentData.response.text;
  } else {
    if (isHTML) {
      inputValue = componentData.response.split(">")[1]?.split("<")?.[0];
    } else {
      inputValue = componentData.response;
    }
  }

  const title = isAdvancedForm ? componentData.title.disableprintpdf ? '' : componentData.title.text : componentData.title;

  const lettersCounter = inputValue?.length;

  const inputRef = useRef(null);

  const getHeightStyle = () => {
    return componentData.maxlines * 15 + 5 + "px";
  };

  const getHTMLStyles = () => {
    let sentStyles;

    if (isAdvancedForm) {
      sentStyles = componentData.response.text !== null ? componentData.response.text.split('style="')[1]?.split('"')[0]: null;
    } else {
      sentStyles = componentData.response.split('style="')[1]?.split('"')[0]; 
    }

    return sentStyles ? `${sentStyles}; height: ${getHeightStyle()}` : `height: ${getHeightStyle()}`;
  }

  useEffect(() => {
    if (!isAdvancedForm && isHTML) {
      const styleString = getHTMLStyles();

      inputRef.current.setAttribute('style', styleString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(isDisabled) {
      onUpdateValue(isAdvancedForm ? {
        ...componentData.response,
        text:''
      }: '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled])

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (hasMaxLength && newValue.length > componentData.maxlength) return;
    onUpdateValue(isAdvancedForm ? {
      ...componentData.response,
      text:newValue
    } : newValue);
  };

  

  const onTextAreaChange = (e) => {
    handleChange(e);
  };

  const getTitleStyles = () => {
    const { fontFamily } = getScreenDimensions();

    const { posx, posy, sizex } = componentData.title.position;
    return Helper.getElementPositionStylesNew(posx, posy, sizex, fontFamily);
  }
 
  const getResponseStyles = () => {
    const { fontFamily } = getScreenDimensions();

    const { posx, posy, sizex } = componentData.response.position;
    const positionStyles = Helper.getElementPositionStylesNew(posx, posy, sizex, fontFamily);

    const styleString = getHTMLStyles();

    const parsedStyles = Helper.getStyleObjectFromString(styleString);

    return {
      ...positionStyles,
      ...parsedStyles,
    };
  }

  return (
    <div
      className={`${isDisabled ? "disabled-container" : ""}  ${
        hasError ? "error-container" : ""
      } ${isAdvancedForm ? 'advanced-form' : ''} textbox-container`}
    >
      {isHTML ? (
        <div
          style={isAdvancedForm ? getTitleStyles() : {}}
          dangerouslySetInnerHTML={Helper.mapInnerHtmlData(title)}
        />
      ) : (
        <label style={isAdvancedForm ? getTitleStyles() : {}}>{title}</label>
      )}
      {hasMaxlines ? (
        <textarea
          style={isAdvancedForm ? getResponseStyles() : {}}
          disabled={isDisabled}
          maxLength={hasMaxLength ? componentData.maxlength : undefined}
          className="textarea-form"
          onChange={onTextAreaChange}
          value={inputValue}
          ref={inputRef}
        />
      ) : (
        <input
          style={isAdvancedForm ? getResponseStyles() : {}}
          disabled={isDisabled}
          type="text"
          className="text-input"
          maxLength={hasMaxLength ? componentData.maxlength : undefined}
          onChange={handleChange}
          value={inputValue}
          ref={inputRef}
        ></input>
      )}
      {hasMaxLength && (
        <div className="letters-counter">
          {lettersCounter}/{componentData.maxlength}
        </div>
      )}
    </div>
  );
};
