import { CHECKBOX, IS_OPENED_FROM_DOCUMENTS_LIST, RADIOBUTTON, RADIOBUTTON_LABEL, TEXTBOX, TITLE } from "../constants/GeneralConstants";

const Helper = {};

export const TYPES_COMPONENTS = {
    CHECKBOX: 'checkbox',
    RADIOBUTTON: 'radiobutton',
    TEXTBOX: 'textbox',
    ATTACHMENT: 'attachments'
};

Helper.base64ToArrayBuffer = (data) => {
  var bString = window.atob(data);
  var bLength = bString.length;
  var bytes = new Uint8Array(bLength);
  for (var i = 0; i < bLength; i++) {
    var ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

Helper.computePressureForSignaturePoints = (signaturePoints) => {
  return signaturePoints.map((stroke, index) => {
    return stroke.map((point) => {
      point["pressure"] = 1;
      return point;
    });
  });
};

Helper.mapInnerHtmlData = (htmlData) => {
    return { __html: htmlData }
}

Helper.clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

Helper.isObject = (item) => {
    return (typeof item === 'object' && item !== null);
}

Helper.objectKeysToLowerCase = (comp) => {
    Object.keys(comp).map((key) => {
        if (key.toLocaleLowerCase() !== key) {
            comp[key.toLocaleLowerCase()] = comp[key];
            delete comp[key];
            if (Helper.isObject(comp[key.toLocaleLowerCase()])) {
                comp[key.toLocaleLowerCase()] = Helper.objectKeysToLowerCase(comp[key.toLocaleLowerCase()]);
            }

        }
    });
    return comp;
}

Helper.advancedObjectKeysToLowerCase = (comp) => {
    if (comp) {
      Object.keys(comp).map((key) => {
        if (key.toLocaleLowerCase() !== key) {
          comp[key.toLocaleLowerCase()] = comp[key];
          delete comp[key];
          if (Helper.isObject(comp[key.toLocaleLowerCase()])) {
            comp[key.toLocaleLowerCase()] = Helper.objectKeysToLowerCase(
              comp[key.toLocaleLowerCase()]
            );
          }
        }
      });
    }
    return comp;
}


Helper.transformFormData = (form) => {
    form = Helper.parseFirstStructure(form);
    const result = form.map(val => {
        const page = val.map(group => {

            // simply object and parse attrs to lowercase
            group.components = group.components.map(comp => {

                comp = Helper.objectKeysToLowerCase(comp);
                comp.title = comp.title.text;
                comp.disable = false;
                comp.error = false;
                if (comp.type === TYPES_COMPONENTS.RADIOBUTTON) {
                    comp.choices = comp.choices.map(choices => choices.Title.Text);
                    comp.selectedchoice = undefined;
                }
                if (comp.type === TYPES_COMPONENTS.CHECKBOX) {
                    comp.response = undefined;
                }
                if (comp.type === TYPES_COMPONENTS.TEXTBOX) {
                    comp.response = comp.response.text;
                }
                if (comp.type === TYPES_COMPONENTS.ATTACHMENT) {
                    comp.response = comp.response;
                }
                return comp;
            });

            return group;
        });
        return page;
    });
    return result;
}

Helper.parseFirstStructure = (form) => {
    const result = form.map(val => {
        const page = val.Groups.map(group => {

            // add Type to object
            group.CheckBoxes = group.CheckBoxes ? Object.assign([], group.CheckBoxes).map(check => {
                check.type = TYPES_COMPONENTS.CHECKBOX; return check;
            }) : [];
            group.RadioButtons = group.RadioButtons ? [...group.RadioButtons].map(check => {
                check.type = TYPES_COMPONENTS.RADIOBUTTON; return check;
            }) : [];
            group.TextBoxes = group.TextBoxes ? [...group.TextBoxes].map(check => {
                check.type = TYPES_COMPONENTS.TEXTBOX; return check;
            }) : [];
            group.Attachments = group.Attachments ? [...group.Attachments].map(attachment => {
                attachment.type = TYPES_COMPONENTS.ATTACHMENT; return attachment;
            }) : [];

            var groupTitle = "";
            if (group.Title)
                groupTitle = group.Title?.Text;
            // join title page with components
            const obj = {
                title: groupTitle,
                components: [].concat(group.CheckBoxes, group.RadioButtons, group.TextBoxes, group.Attachments)
            };
            return obj;
        });
        return page;
    });
    return result;
}

Helper.transformAdvancedFormData = (form) => {
    form = Helper.parseFirstAdvancedFormStructure(form);
    const result = form.map(val => {
      const page = val.map(group => {
        // simply object and parse attrs to lowercase
        group.components = group.components.map(comp => {
          comp = Helper.advancedObjectKeysToLowerCase(comp);
          comp.title = comp.title;
          comp.disable = false;
          comp.error = false;
          if (comp.type === TYPES_COMPONENTS.RADIOBUTTON) {
            comp.choices = comp.choices.map(choices => Helper.advancedObjectKeysToLowerCase(choices));
            comp.selectedchoice = comp.selectedchoice === 0 ? undefined : comp.selectedchoice;
          }
          if (comp.type === TYPES_COMPONENTS.CHECKBOX) {
            comp.response = comp.response;
          }
          if (comp.type === TYPES_COMPONENTS.TEXTBOX) {
            comp.response = comp.response;
          }
          return comp;
        });
  
        return group;
      });
      return page;
    });
    
    return result;
  }
  
Helper.parseFirstAdvancedFormStructure = (form) => {
    const result = form.Sections.map( val => {
      const page = val.Groups.map( group => {
  
        // add Type to object
        group.CheckBoxes = group.CheckBoxes ? Object.assign([], group.CheckBoxes).map(check => {
          check.type = TYPES_COMPONENTS.CHECKBOX ; return check;
        }) : [];
        group.RadioButtons = group.RadioButtons ? [...group.RadioButtons].map(check => {
          check.type = TYPES_COMPONENTS.RADIOBUTTON; return check;
        }) : [];
        group.TextBoxes = group.TextBoxes ? [...group.TextBoxes].map(check => {
          check.type = TYPES_COMPONENTS.TEXTBOX; return check;
        }) : [];
        group.Attachments = group.Attachments ? [...group.Attachments].map(check => {
          check.type = TYPES_COMPONENTS.ATTACHMENT; return check;
        }) : [];
  
        var groupTitle = "";
        if(group.Title !== undefined)
        groupTitle = Helper.advancedObjectKeysToLowerCase(group.Title);
        // join title page with components
        const obj = {
          title : groupTitle,
          components : [].concat(group.CheckBoxes, group.RadioButtons, group.TextBoxes, group.Attachments)
        };
        return obj;
      });
      return page;
    });
    return result;
  }

  Helper.getAdvancedFormInfo = (formTransform, currentPage, totalPageNumber) => {
    var arrayPages = Array.from(Array(totalPageNumber).keys());
    var resultFlat = formTransform.flat();
    const components = resultFlat.flatMap(component => {
      return component.components
    });
    const titles = resultFlat.flatMap( component => {
      return component.title
    });
    let maxPageWithQuestions = 0;
    arrayPages.forEach((page, index) => {
      var pageComponents = components.filter(component => {
        return component.title.position.page -1 === index
      });
      var pageTitles = titles.filter(title => {
        if(title === "")
          return false;
        return title?.position?.page -1 === index
      });
      if (pageComponents.length > 0) {
        maxPageWithQuestions = page;
      }
      arrayPages[index] = { title: pageTitles, components: pageComponents } 
    });
    return { "pageData": arrayPages[currentPage], "maxPageWithQuestions": maxPageWithQuestions + 1 };
  }

Helper.responsesToForm = (form, formResponse, isAdvancedForm = false) => {
    const sections = form.Sections.map((section) => {
        section.Groups.map((group) => {
            if (('RadioButtons' in group) && group.RadioButtons !== null && group.RadioButtons.length > 0) {
                group.RadioButtons.map((radiobutton) => {
                    const response = formResponse.filter((_response) => {
                        return _response.id === radiobutton.Id;
                    });
                    if (response.length > 0) {
                        radiobutton.SelectedChoice = +(response[0].response) + 1;
                    }

                    return radiobutton;
                });
            }

            if (('CheckBoxes' in group) && group.CheckBoxes !== null && group.CheckBoxes.length > 0) {
                group.CheckBoxes.map((checkbox) => {
                    const response = formResponse.filter((_response) => {
                        return _response.id === checkbox.Id;
                    });
                    if (response.length > 0) {
                        checkbox.Response = response[0].response;
                    }

                    return checkbox;
                });
            }

            if (('TextBoxes' in group) && group.TextBoxes !== null && group.TextBoxes.length > 0) {
                group.TextBoxes.map((textbox) => {
                    const response = formResponse.filter((_response) => {
                        return _response.id === textbox.Id;
                    });

                    if (!isAdvancedForm) textbox.Response.Text = "";

                    if (response.length > 0) {
                        isAdvancedForm ? textbox.Response = response[0].response : textbox.Response.Text = response[0].response;
                    }

                    return textbox;
                });
            }

            if (('Attachments' in group) && group.Attachments !== null && group.Attachments.length > 0) {
                group.Attachments.map((attachment) => {
                    const response = formResponse.filter((_response) => {
                        return _response.id === attachment.Id;
                    });
                    if (response.length > 0) {
                        const base64Data = response[0].response.fileinbase64;
                        attachment.Response.FileInBase64 = base64Data?.split("base64,")[1];
                        attachment.Response.Position = response[0].response.position;
                        attachment.Response.Source = response[0].response.source;
                    }

                    return attachment;
                });
            }

            return group;
        });

        return section;
    });

    form.Sections = sections;
    return form;
};

const PIXEL_TO_MM_FACTOR = 0.26458333333;

Helper.getScaleDependingOnScreen = () => {
  // To calculate image heights of different devices widths, you have divide the width between 0.7058823529411765 
  // (which is the accurate resolution for an A4 page, 396/561) 

  // Scale calculated dividing .pdf-images image width between Joako's project width (396px). 
  // Which is the conversion from A4 dimensions in mm to px. 

  // To add a new device, follow these steps:
  // 1. Get the width of the new device
  // 2. Divide that width between 0.7058823529411765 to get the height. This is for the .pdf-images dimension in CSS
  // 3. Divide that width between 396px and you will get the device factor, add below

  // Desktop factor is 623.66px between 396px = 1.574898px
  const DESKTOP_FACTOR = 1.574898;
  // Tablet factor is 513.66px between 396px = 1.27186868px
  const TABLET_FACTOR = 1.27186868;
  // Small factor is 390px (iPhone XR's dimensions) between 396px = 0.9848
  const SMALL_MOBILE_FACTOR = 0.9848;
  // Large factor is 576px (BlackBerry PlayBook's dimensions) between 396px = 1.4545
  const LARGE_MOBILE_FACTOR = 1.4545;

  const screenWidth = window.innerWidth;

  // Scale based on device
  if (screenWidth <= 450) return SMALL_MOBILE_FACTOR;
  if (screenWidth > 450 && screenWidth <= 600) return LARGE_MOBILE_FACTOR;
  if (screenWidth > 600 && screenWidth <= 1200) return TABLET_FACTOR;
  if (screenWidth > 1200) return DESKTOP_FACTOR;
}

Helper.transformMMtoPx = (value, scaleModifier) => {
  const SCALE = 2 / scaleModifier;

  return parseInt((value / PIXEL_TO_MM_FACTOR) / SCALE);
}

Helper.getElementPositionStylesNew = (posX, posY, sizeX, fontFamily) => {
  const TABLET_FACTOR = 1.27186868;
  const scaleModifier = Helper.getScaleDependingOnScreen();
  sizeX = sizeX > 210 ? 210 : sizeX;
  posX = posX + sizeX <= 210 ? posX : 210 - sizeX;

  let elementPosXpixels = Helper.transformMMtoPx(posX, scaleModifier);
  const elementPosYpixels = Helper.transformMMtoPx(posY, scaleModifier);
  const elementWpixels = Helper.transformMMtoPx(sizeX, scaleModifier);
  const elementHpixels = 10;
  const fontSizePixels = 10;

  if (posX >= 20 && scaleModifier === TABLET_FACTOR) {
    elementPosXpixels = elementPosXpixels * 0.95;
  }

  const baseZIndex = 15;
  const additionalZIndex = Math.floor(posX / 10);
  const zIndex = baseZIndex + additionalZIndex;

  return {
    position: "absolute",
    top: elementPosYpixels + "px",
    left: elementPosXpixels + "px",
    width: elementWpixels + "px",
    height: elementHpixels + "px",
    zIndex: zIndex,
    fontSize: fontSizePixels + "px",
    fontFamily: fontFamily,
    display: "flex",
    paddingLeft: 'unset',
    marginTop: 'unset'
  };
}

Helper.getElementPositionStyles = (posX, posY, sizeX, width, height, fontSizeLayout, elementType = "") => {
  const isRadioButton = elementType === RADIOBUTTON;
  const isRadioButtonLabel = elementType === RADIOBUTTON_LABEL;
  const isCheckbox = elementType === CHECKBOX;
  const isTitle = elementType === TITLE;
  const isTextbox = elementType === TEXTBOX;
  
  //A4 page base
  const heightBase = 841.92; //A4 sizeY page px
  const widthBase = 595.32; //A4 sizeX page px
  const convertionFactorBase = 0.3528;
  const heightInMM = heightBase * convertionFactorBase;
  const fontSize = fontSizeLayout;
  const PDF_FONT_HEIGHT = 0.3527;
  const lineHeight = (fontSize * PDF_FONT_HEIGHT) / convertionFactorBase;
  let POSX_CORRECTIONFACTOR = 0;
  let POSY_CORRECTIONFACTOR = 1.4;  
  let CHECKBOX_SCALE_FIT = 15;
  let fontSizeCalculate = fontSize * 2;
  
  posX = posX + sizeX <= 210? posX: 210 - sizeX;
  sizeX = posX + sizeX > width? width - posX: sizeX;
  posX = isRadioButton? posX - 2: posX;
  posY = isRadioButton? posY - 1: posY;

  if (width < 600 && isRadioButton) {
    posX = posX - 5;
    posY = posY - 1;
  }

  if (width < 600 && isRadioButtonLabel) {
    posX = posX + 2;
    posY = posY + 3;
  }

  if (width < 600 && isCheckbox) {
    posX = posX - 2;
    posY = posY + 2;
  }
  
  if (width > 600 && isCheckbox) {
    posY = posY - 1;
  }
  
  if (width > 600 && (isRadioButton)) {
    posX = posX + 1;
  }

  if (width > 600 && (isRadioButtonLabel)) {
    posX = posX - 1;
    posY = posY - 1;
  }

  if (width > 600 && isTitle) {
    posY = posY - 1;
  }
 
  // if (width > 600 && isTextbox) {
  //   posY = posY - 2;
  // }
  
  if(width < 500){
    fontSizeCalculate = fontSize / 2;
  }

  if(width > 500 && width < 1000){
    fontSizeCalculate = parseInt(fontSize) + 2;
  }

  //  Calculations based on screen sizes
  if (width < 400) {

  } else if (width >= 400 && width < 600) {

  } else if (width >= 600 && width < 800) {

  } else {

  }

  let posxbase = (posX / convertionFactorBase) - POSX_CORRECTIONFACTOR;
  let posybase = (heightBase - (((heightInMM - posY) / convertionFactorBase) - (POSY_CORRECTIONFACTOR * lineHeight))) - CHECKBOX_SCALE_FIT;
  let sizexbase = sizeX / convertionFactorBase;

  let posxpercent = null;
  let posxcalculated = null;

  posxpercent = ((width - widthBase) / widthBase) * 100;
  posxcalculated = posxbase + ((posxbase * posxpercent) / 100);
  
  let posypercent = null;
  let posycalculated = null;

  posypercent = ((height - heightBase) / heightBase) * 100;
  posycalculated = posybase + ((posybase * posypercent) / 100);

  let sizexcalculated = sizexbase + ((sizexbase * posxpercent) / 100) + 'px';

  if (isRadioButton || isCheckbox) {
    sizexcalculated = 'unset';
  }

  return {
    position: "absolute",
    top: posycalculated + "px",
    left: posxcalculated + "px",
    width: sizexcalculated,
    zIndex: "15",
    fontSize: fontSizeCalculate + "px",
    fontFamily: "Times-Roman",
    display: "flex",
    paddingLeft: 'unset',
    marginTop: 'unset'
  };
}

Helper.formatStringToCamelCase = str => {
  const splitted = str.split("-");
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map(word => word[0].toUpperCase() + word.slice(1))
      .join("")
  );
};
Helper.getStyleObjectFromString = str => {
  const style = {};
  str.split(";").forEach(el => {
    const [property, value] = el.split(":");
    if (!property) return;

    const formattedProperty = Helper.formatStringToCamelCase(property.trim());
    style[formattedProperty] = value.trim();
  });

  return style;
};

Helper.base64ArrayBuffer = (arrayBuffer) => {
  let base64 = '';
  const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  const bytes = new Uint8Array(arrayBuffer);
  const byteLength = bytes.byteLength;
  const byteRemainder = byteLength % 3;
  const mainLength = byteLength - byteRemainder;

  let a;
  let b;
  let c;
  let d;
  let chunk;

  for (let i = 0; i < mainLength; i += 3) {
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    a = (chunk & 16515072) >> 18;
    b = (chunk & 258048) >> 12;
    c = (chunk & 4032) >> 6;
    d = chunk & 63;

    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  if (byteRemainder === 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2;
    b = (chunk & 3) << 4;

    base64 += `${encodings[a]}${encodings[b]}==`;
  } else if (byteRemainder === 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10;
    b = (chunk & 1008) >> 4;
    c = (chunk & 15) << 2;

    base64 += `${encodings[a]}${encodings[b]}${encodings[c]}=`;
  }

  return base64;
}

Helper.setIsOpenedFromDocumentList = (isOpenedFromDocumentList) => {
  localStorage.setItem(IS_OPENED_FROM_DOCUMENTS_LIST, isOpenedFromDocumentList);
}

Helper.getIsOpenedFromDocumentList = () => {
  return localStorage.getItem(IS_OPENED_FROM_DOCUMENTS_LIST);
}

Helper.getTruncatedSignerName = (signerName) => {
  if (signerName) {
    let splittedSignerName = signerName?.split('');
    const lettersAmount = splittedSignerName?.length;

    if (lettersAmount <= 25) {
      return signerName;
    }

    const first24Chars = splittedSignerName?.splice(0, 24);
    return `${first24Chars.join('')}...`;
  }
}

export default Helper;