import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import './DocumentQuestions.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Helper from '../../util/helper';
import { Question } from '../../components/Question/Question';
import { FormContext } from '../../contexts/FormContext';
import { useTranslation } from 'react-i18next';
import { ATTACHMENT, CHECKBOX, FIRST_LOAD, IS_FROM_BATCH_LIST, MANDATORY, RADIOBUTTON, TEXTBOX } from "../../constants/GeneralConstants";
import SignatureService from '../../services/SignatureService';
import { QuestionsErrorModal } from '../../components/Modals/QuestionsErrorModal/QuestionsErrorModal';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { HandIcon } from '../../components/SvgComponents/HandIcon/HandIcon';
import { Back } from '../../components/Back/Back';
import { ShowErrorModal } from '../../components/Modals/ShowErrorModal/ShowErrorModal';
import { Back2Icon } from '../../components/SvgComponents/Back2Icon/Back2Icon';

export const DocumentQuestions = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const document = location.state.documents;
  const emailId = location.state.emailId;

  const { form, setForm, formTransform, setFormTransform, checkDisabled } = useContext(FormContext);
  const [openQuestionErrorModal, setOpenQuestionErrorModal] = useState(false);
  const isFromBatchList = localStorage.getItem(IS_FROM_BATCH_LIST) === "true";
  const [currentSection, setCurrentSection] = useState(0);
  const [mandatoryFieldError, setMandatoryFieldError] = useState(false);

  useEffect(() => {
    initDocumentQuestion();
  }, [document]);

  useEffect(() => {
    window.document.querySelector('#behavior-scroll').scrollIntoView({ behavior: 'smooth' });
  }, [currentSection, openQuestionErrorModal]);

  const initDocumentQuestion = () => {
    if (document?.SignerDTO?.Form) {
      setForm(document.SignerDTO.Form);
      const formData = document.SignerDTO.Form;
      const clonedForm = Helper.clone(formData.Sections || []);
      const transformedForm = Helper.transformFormData(clonedForm);
      setFormTransform(transformedForm || []);
    }
  }

  const handleNextSection = () => {
    if (!validateSection(currentSection)) {
      setOpenQuestionErrorModal(true);
      return;
    }

    if (currentSection < formTransform.length - 1) {
      setCurrentSection(currentSection + 1);
    } else {
      const array = [].concat(...formTransform);
      let result = [].concat(...array.map(val => val.components.map(comp => {
        return { id: comp.id, response: ((comp.response === undefined || comp.response === null) ? comp.selectedchoice : comp.response) };
      })));
      result = result.filter(val => val.response !== undefined);
      const signerGuid = document.SignerDTO.SignerGUI;
      setLoading(true);
      SignatureService.signatureFormResponse(signerGuid, form, result, false)
        .then(response => {
          const link = "/document";
          window.scrollTo(0, 0);
          navigate(link, {
            state: {
              documents: document,
              emailId: emailId,
              isFromQuestion: true
            }
          });
          localStorage.setItem(FIRST_LOAD, "false")
        })
        .catch(err => {
          console.log(err);
          const errorMessage = err.response.data;
          if (errorMessage.includes('bigger') || errorMessage.includes('greater')) {
            setError(t('document_id.file_size_limit_exceeded'));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleBack = () => {
    let link = "/document"

    navigate(link, {
      state: {
        documents: document,
        emailId: emailId,
      }
    });
  };

  const isAfterPreview = () => {
    return document.SignerDTO.Form.FormDisplay === "AfterPreview";
  }

  const checkBackNavigation = () => {
    return (isAfterPreview() || isFromBatchList);
  }

  const handlePreviousSection = () => {
    initDocumentQuestion();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
    }
  };

  const validateSection = (sectionIndex) => {
    setMandatoryFieldError(false);
    let result = true;
    const isAdvancedForm = form.FormDisplay.toLowerCase() === "onpreview";
    const section = formTransform[sectionIndex];

    section.forEach((question, j) => {
      question.components = question.components.map((comp, k) => {
        if (comp.type === ATTACHMENT && comp.required === MANDATORY && !comp.response.fileinbase64) {
          result = false;
          comp.error = true;
        }
        else if (comp.type === CHECKBOX && comp.appearance === RADIOBUTTON) {
          if ((typeof comp.response === 'undefined' || comp.response === null) && !checkDisabled(comp)) {
            result = false;
            comp.error = true;
          } else {
            if (comp.required === MANDATORY && !comp.response) {
              result = false;
              comp.error = true;
              setMandatoryFieldError(true);
            }
          }
        }
        else if (comp.type === CHECKBOX && comp.appearance === CHECKBOX) {
          if (typeof comp.response === 'undefined' && comp.required === MANDATORY) {
            result = false;
            comp.error = true;
            setMandatoryFieldError(true);
          }
        }
        else if (comp.type === TEXTBOX && !checkDisabled(comp)) {
          const responseText = isAdvancedForm ? (comp.response.text === "" || comp.response.text === null) : (comp.response === "" || comp.response === null);
          if ((comp.required === MANDATORY || !comp.optional) && responseText) {
            result = false;
            comp.error = true;
          }
        }
        else if (comp.type === RADIOBUTTON && !checkDisabled(comp) && (comp.selectedchoice === undefined || comp.selectedchoice === null) && comp.required === MANDATORY) {
          result = false;
          comp.error = true;
        } else {
          comp.error = false;
        }
        if (comp.error) {
          const clonedForm = Helper.clone(formTransform);
          clonedForm[sectionIndex][j]["components"][k] = comp;
          setFormTransform(clonedForm);
        }

        return comp;
      });
    });

    return result;
  };

  return (
    <>
      <Container className="container-row row-box form-view">
        <Row>
          {checkBackNavigation() &&
            <Col md={12} lg={12} className="document-preview-title" style={{ paddingLeft: '0px' }}>
              <Back handleBack={handleBack} />
            </Col>
          }
          <div className="welcome-container" style={{ paddingLeft: '0px' }} >
            {!checkBackNavigation() && <HandIcon />}
            <div id="behavior-scroll" className="welcome-text-container">
              <h2>{t("general.hello")} {document?.SignerDTO?.SignerName}</h2>
              <p>{t('question.complete_information')}</p>
            </div>
          </div>
          <hr className="welcome-separator" />
          {formTransform &&
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${((currentSection + 1) / formTransform.length) * 100}%` }}
                aria-valuenow={((currentSection + 1) / formTransform.length) * 100}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          }
          <div className="page-indicator">
            {formTransform && Array.isArray(formTransform) && formTransform.length > 0 &&
              `${currentSection + 1}/${formTransform.length}`
            }
          </div>
          {formTransform && <Question currentSection={currentSection} />}
        </Row>
        <Row className="action-buttons">
          <Col md={1} lg={1} xs={0} sm={0}></Col>
          <Col md={5} lg={5} xs={6} sm={6}>
            {currentSection > 0 &&
              <button
                type="button"
                className="btn btn-secondary btn-cancel"
                onClick={handlePreviousSection}
              >
                <span className="cancel-text"> {t('general.back')} </span>
                <Back2Icon />
              </button>
            }
          </Col>
          <Col md={5} lg={5} xs={6} sm={6}>
            <button
              type="button"
              className="btn btn-primary btn-active"
              onClick={handleNextSection}
              disabled={loading}
            >
              <span className="cancel-text">
                {t('general.next')}
              </span>
              <ArrowIcon disabled={loading} />
            </button>
          </Col>
          <Col md={1} lg={1} xs={1} sm={1}></Col>
        </Row>
      </Container>
      <QuestionsErrorModal setOpenQuestionErrorModal={setOpenQuestionErrorModal} openQuestionErrorModal={openQuestionErrorModal} mandatoryFieldError={mandatoryFieldError} />
      <ShowErrorModal openShowErrorModal={error} setOpenShowErrorModal={setError} errorMessage={error} />
    </>
  );
};
