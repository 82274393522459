import { Col, Container, Form, Row } from "react-bootstrap";
import './AttachImages.css';
import { useState } from "react";
import { DNIFlow } from "./DNIFlow/DNIFlow";
import { PassportFlow } from "./PassportFlow/PassportFlow";
import { useTranslation } from "react-i18next";
import { Back } from "../../components/Back/Back";
import { useNavigate, useLocation } from 'react-router-dom';
import { ViewDocumentIcon } from "../../components/SvgComponents/ViewDocumentIcon/ViewDocumentIcon";

export const AttachImages = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState("");


  const handleBack = () => {
    let link = "/document";
    navigate(link, {
      state: {
        documents: location.state.documents,
        emailId: location.state.emailId,
        otpCode: location.state.otpCode
      }
    });
  };


  const handleRadioButtonChange = (e) => {
    setSelectedType(e.target.id);
  };

  return (
    <Container className="container-row row-box form-view">
      <Row>
        <Col md={12} lg={12} className="document-preview-title padding-left">
          <Back handleBack={handleBack} />
          <span className="signer-name">
            {t("document_id.attachment_instruction")}
          </span>
        </Col>
      </Row>
      <Row className="select-type-container">
        <Col lg={4} xs={12}>
          <ViewDocumentIcon />
        </Col>
        <Col lg={8} xs={12} className="select-type-right-container">
          <h3 className="select-type-title">{t("document_id.select_type")}</h3>
          <div className="document-type-choices">
            <Form.Check
              type="radio"
              id="DNI"
              name={"docType"}
              label={t("document_id.dni")}
              onChange={handleRadioButtonChange}
            />
            <Form.Check
              type="radio"
              id="Passport"
              name={"docType"}
              label={t("document_id.passport")}
              onChange={handleRadioButtonChange}
            />
          </div>
        </Col>
      </Row>
      {selectedType === "DNI" && <DNIFlow />}
      {selectedType === "Passport" && <PassportFlow />}
    </Container>
  );
};
