import { createContext, useState } from "react";

export const URLReturnContext = createContext(null);

export const URLReturnContextProvider = ({ children }) => {
    const [urlReturn, setURLReturn] = useState(null);

    const decodeURL = (baseURL) => {
        const decodedURL = atob(baseURL);

        setURLReturn(decodedURL);
    }
    
    return (
        <URLReturnContext.Provider value={{
            urlReturn,
            decodeURL,
        }}>
            {children}
        </URLReturnContext.Provider>
    );
};