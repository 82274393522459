import { Col, Row } from "react-bootstrap";
import documentLoadingGif from './../../../assets/images/logo_vidsigner_green.gif';
import whiteLoaderGif from '../../../assets/images/white_loader.gif';
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import { OTPDraw } from "../../SignMethods/OTPDraw/OTPDraw";
import { BatchContext } from "../../../contexts/BatchContext";
import { useLocation } from 'react-router-dom';

import './DisclaimerModal.css';
import { IS_CUSTOM } from "../../../constants/GeneralConstants";
import { CheckIcon } from "../../SvgComponents/CheckIcon/CheckIcon";

export const DisclaimerModal = ({ setOpenDisclaimerModal }) => {
  const { t } = useTranslation();
  const [otpDraw, setOTPDraw] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCustom, setIsCustom] = useState(false);

  const { selectedBatchDocuments } = useContext(BatchContext);

  const signerGuid = selectedBatchDocuments[0].SignerDTO.SignerGUI;

  const location = useLocation();
  const { emailId } = location.state;

    // On Init
    useEffect(() => {
        const isCustom = localStorage.getItem(IS_CUSTOM) === "true";
        setIsCustom(isCustom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const handleCloseModal = () => {
    setOpenDisclaimerModal(false);
  }

  const handleContinue = () => {
    setIsLoading(true);
    setOTPDraw(true);
  }

  return (
    <>
        <Modal
            title={t("document_warning.title")}
            footer={null}
            open={true}
            closable={true}
            onCancel={handleCloseModal}
        >
        <div className="modal-container disclaimer-modal">
        {isLoading ? (
                <div className="loader-container">
                    <img src={isCustom ? whiteLoaderGif : documentLoadingGif} alt="LoadingGif" />
                </div>
            ) :<>
                <p className="nu-input-label">{t("document_warning.description")}</p>
                <Col className="action-buttons mobile-button">
                    <Row>
                        <Col md={3} xs={3}></Col>
                        <Col md={6} xs={6}>
                            <button
                                type="button"
                                className="btn btn-primary btn-active"
                                onClick={() => handleContinue()}
                            >
                                <span className="cancel-text">{t("general.ok")}</span>
                                <CheckIcon />
                            </button>
                        </Col>
                        <Col md={3} xs={3}></Col>
                    </Row>
                </Col>
            </> 
            }
        </div>
        </Modal>
        {otpDraw && <OTPDraw setLoadingMorePages={setIsLoading} documents={selectedBatchDocuments} signerGuid={signerGuid} emailId={emailId} otpCode='' attachedImages={false} />}
    </>
  );
};
