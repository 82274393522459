import { MAX_AGE_TIME } from '../constants/GeneralConstants';
import { managementPostRequest } from '../vidCloud/managementRequest';
import Cookies from 'js-cookie';
class OauthService {
  static getToken(emailId) {
    const requestDTO = {
      username: emailId,
      password: "",
      grant_type: "password",
      scope: "email",
    };

    return managementPostRequest("/oauth/token", requestDTO)
      .then((response) => {
        const token = response.data.access_token;
        Cookies.set('token', token, {
          expires: MAX_AGE_TIME,
          secure: true,
          sameSite: 'none',
          httpOnly: false,
        });
        return { success: true, token };
      })
      .catch((error) => {
        console.error('Error en getToken:', error);
        window.location.href = "/document-error";
        return {
          success: false,
          error: error.response?.data || 'Error desconocido',
        };
      });
  }
}

export default OauthService;