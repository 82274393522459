import { privGetRequest } from "../vidCloud/priv";

const RgpdTextService = {};

RgpdTextService.rgpdText = (signerGuid, language) => {
  return privGetRequest(`/pendingsignatures/${signerGuid}/rgpdtext/${language}`);
}

RgpdTextService.rgpdAgreeText = (signerGuid, language) => {
  return privGetRequest(`/pendingsignatures/${signerGuid}/rgpdagreetext/${language}`);
}

export default RgpdTextService;