import { detect } from 'detect-browser';
import { EVIDENCE_DATA } from '../constants/GeneralConstants';

const getLocation = () => {
  return new Promise((resolve) => {
      if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                  const latitude = position.coords.latitude;
                  const longitude = position.coords.longitude;
                  resolve(`${latitude},${longitude}`);
              },
              () => {
                  // Error
                  resolve("");
              }
          );
      } else {
          // Not available
          resolve("");
      }
  });
}

export const getEvidenceData = async () => {
  let evidenceLocation = '';
  let browserStringified = '';
  let evidenceInfo = {};

  try {
    let responseData;
    const saveEvidences = sessionStorage.getItem(EVIDENCE_DATA);
    if (saveEvidences) {
      responseData = JSON.parse(saveEvidences);
    } else {
      const browser = detect();
      responseData = browser;
      sessionStorage.setItem(EVIDENCE_DATA, JSON.stringify(responseData));
    }
    browserStringified = JSON.stringify(responseData);

    evidenceLocation = await getLocation();
  } catch (e) {
    console.log(e)
  }
  evidenceInfo = {
    IpAddress: '',
    Location: evidenceLocation,
    From: browserStringified
  };

  return evidenceInfo;
};