import axios from 'axios';

const axiosInstance = axios.create({
  allow_headers: ['Content-Type', 'Authorization', 'locale'],
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 403 && error.response.data?.includes('The Personal Code has expired')) {
        window.location.href = '/expired-personal-code';
        return Promise.reject(error);
      }
      if (error.response.status === 409 && (error.response.data?.includes('Is no next signature.Signature') || error.response.data.includes('has been rejected'))) {
        window.location.href = '/document-error';
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

export { axiosInstance };