import React, { useState } from 'react';
import "./Support.css";
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import SupportTicketService from '../../services/SupportTicketService';
import { Loader } from '../../components/Loader/Loader';
import { Back } from '../../components/Back/Back';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { SmsSendingIcon } from '../../components/SvgComponents/SmsSendingIcon/SmsSendingIcon';


export const Support = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  var isPhoneNumberCustom = location.state?.isPhoneNumberCustom;
  var isCallChannel = location.state?.isCallChannel;
  const [successMessage, setSuccessMessage] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const widthIcon = 150;
  const heightIcon = 147;


  const handleBack = () => {
    let link;
    if (isPhoneNumberCustom || isCallChannel) {
      link = "/customsms";
      navigate(link, {
        state: {
          documents: location.state.documents,
          phoneNumber: location.state.phoneNumber,
          emailId: location.state.emailId
        }
      });
      return;
    }

    if (!isPhoneNumberCustom && !isCallChannel) {
      link = "/sms";
    }
    navigate(link, {
      state: {
        documents: location.state.documents,
        phoneNumber: location.state.phoneNumber,
        emailId: location.state.emailId
      }
    });
  }

  const sendSupportTicket = () => {
    setLoading(true);
    const signerGUI = location.state.documents.SignerDTO.SignerGUI;
    const body = {
      "Code": "SMS_NOTRECEIVED",
      "SignerGUID": signerGUI,
      "AdditionalInfo": additionalInfo
    };
    SupportTicketService.sendSupportTicket(body)
      .then(response => {
        setLoading(false);
        setSuccessMessage(true);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleAdditionalInfoChange = (e) => {
    const value = e.target.value;
    setAdditionalInfo(value);
  };

  return (
    <>
      {loading && <Loader />}
      {!loading &&
        <Container className="document-preview-container">
          {!successMessage &&
            <div>
              <Row>
                <Col md={12} lg={12} className="document-preview-title">
                  <Back handleBack={handleBack} />
                </Col>
                <Col md={2} xs={1}></Col>
                <Col md={12} xs={10} lg={12} className="justify-content-center container-sms-box-2">
                  <Row>
                    <Col md={4} lg={4}>
                      <SmsSendingIcon width={widthIcon} height={heightIcon} />
                    </Col>
                    <Col md={5} lg={5} className="col-align">
                      <Form className="phone-number-form">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label>{t('support.step1_text1')}</Form.Label>
                          <Form.Label>{t('support.step1_text2')}</Form.Label>
                          <Form.Control as="textarea" rows={3} value={additionalInfo} onChange={handleAdditionalInfoChange} />
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={0}></Col>
                <Col md={4} xs={12} className="button-margin button-only">
                  <button type="button" className="btn btn-primary btn-active phone-number-button" disabled={additionalInfo.length === 0} onClick={() => sendSupportTicket()}>
                  <span className="cancel-text">{t('general.sign')}</span>
                    <ArrowIcon disabled={additionalInfo.length === 0} />
                  </button>
                </Col>
              </Row>
            </div>
          }
          {successMessage &&
            <div>
              <span>{t('support.step2_text1')}</span>
            </div>
          }
        </Container>
      }
    </>
  );
}

