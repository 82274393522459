import { Col, Container, Row } from 'react-bootstrap';
import documentLoadingGif from '../../assets/images/logo_vidsigner_green.gif';
import whiteLoaderGif from '../../assets/images/white_loader.gif';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { IS_CUSTOM } from '../../constants/GeneralConstants';

export const Loader = ({ loaderMessage }) => {
    const { t } = useTranslation();
    const [isCustom, setIsCustom] = useState(false);
    const message = loaderMessage === undefined ? t('general.loading') : loaderMessage;
    // On Init
    useEffect(() => {
        const isCustom = localStorage.getItem(IS_CUSTOM) === "true";
        setIsCustom(isCustom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Container>
                <Row className="container-row">
                    <Col md={2}></Col>
                    <Col md={8} xs={12} className="justify-content-center container-loading-box">
                        <Row>
                            <Col md={12}>
                                <img src={isCustom ? whiteLoaderGif : documentLoadingGif} alt="LoadingGif" />
                            </Col>
                            <Col md={12}>
                                <br></br>
                                <h4 className="title-loading-documents">{message}</h4>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};