import React, { useEffect, useState } from 'react';

export const BackIcon = () => {

  const [color, setColor] = useState('');
  const [isArabic, setIsArabic] = useState(false);
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-primary-background-color");
    setColor(color);
    const lang = localStorage.getItem('i18nextLng');
    setIsArabic(lang === 'ar');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isArabic ? (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g transform="scale(-1, 1)" transform-origin="center">
            <path fillRule="evenodd" clipRule="evenodd" d="M22.7006 17.1894C23.2863 17.1099 23.7378 16.6079 23.7378 16.0004C23.7378 15.3376 23.2005 14.8004 22.5378 14.8004L12.3671 14.8004L16.3309 10.854L16.4474 10.7197C16.797 10.2507 16.7597 9.58393 16.3346 9.15699C15.8669 8.68737 15.1071 8.68575 14.6375 9.15338L8.6151 15.1502L8.61099 15.1549C8.57263 15.1936 8.53687 15.2348 8.50402 15.2784L8.49831 15.2849C8.14795 15.7554 8.18688 16.4244 8.6151 16.8508L14.6375 22.8476L14.7723 22.9635C15.2429 23.3111 15.9094 23.271 16.3346 22.844L16.4505 22.7092C16.798 22.2387 16.7579 21.5721 16.3309 21.147L12.369 17.2004H22.5378L22.7006 17.1894Z" fill={color} />
            <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M32 9.0656L32 22.936C32 28.3818 28.1131 32 22.5344 32L9.4656 32C3.89273 32 0 28.371 0 22.936L0 9.0656C0 3.61954 3.8872 0 9.4656 0L22.5344 0C28.1128 0 32 3.61954 32 9.0656ZM29.5999 22.9356L29.5999 9.06515C29.5999 4.98435 26.8239 2.39955 22.5343 2.39955L9.46549 2.39955C5.17583 2.39955 2.39989 4.98435 2.39989 9.06515L2.39989 22.9356C2.39989 27.006 5.18194 29.5996 9.46549 29.5996L22.5343 29.5996C26.8244 29.5996 29.5999 27.0159 29.5999 22.9356Z" fill={color} />
          </g>
        </svg>
      ) : (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M22.7006 17.1894C23.2863 17.1099 23.7378 16.6079 23.7378 16.0004C23.7378 15.3376 23.2005 14.8004 22.5378 14.8004L12.3671 14.8004L16.3309 10.854L16.4474 10.7197C16.797 10.2507 16.7597 9.58393 16.3346 9.15699C15.8669 8.68737 15.1071 8.68575 14.6375 9.15338L8.6151 15.1502L8.61099 15.1549C8.57263 15.1936 8.53687 15.2348 8.50402 15.2784L8.49831 15.2849C8.14795 15.7554 8.18688 16.4244 8.6151 16.8508L14.6375 22.8476L14.7723 22.9635C15.2429 23.3111 15.9094 23.271 16.3346 22.844L16.4505 22.7092C16.798 22.2387 16.7579 21.5721 16.3309 21.147L12.369 17.2004H22.5378L22.7006 17.1894Z" fill={color} />
          <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M32 9.0656L32 22.936C32 28.3818 28.1131 32 22.5344 32L9.4656 32C3.89273 32 0 28.371 0 22.936L0 9.0656C0 3.61954 3.8872 0 9.4656 0L22.5344 0C28.1128 0 32 3.61954 32 9.0656ZM29.5999 22.9356L29.5999 9.06515C29.5999 4.98435 26.8239 2.39955 22.5343 2.39955L9.46549 2.39955C5.17583 2.39955 2.39989 4.98435 2.39989 9.06515L2.39989 22.9356C2.39989 27.006 5.18194 29.5996 9.46549 29.5996L22.5343 29.5996C26.8244 29.5996 29.5999 27.0159 29.5999 22.9356Z" fill={color} />
        </svg>
      )}
    </>
  );
}