import React, { Fragment, useContext } from 'react';
import { TextBox } from "../TextBox/TextBox";
import { RadioButton } from "../RadioButton/RadioButton";
import { Checkbox } from "../Checkbox/Checkbox";
import { Attachment } from "../Attachment/Attachment";
import { FormTitle } from "../FormTitle/FormTitle";
import { ATTACHMENT, CHECKBOX, MANDATORY, RADIOBUTTON, TEXTBOX } from "../../constants/GeneralConstants";
import { FormContext } from "../../contexts/FormContext";
import Helper from "../../util/helper";

export const Question = ({ currentSection }) => {
  const { formTransform, setFormTransform, checkDisabled } = useContext(FormContext);

  const section = formTransform[currentSection];

  const renderSection = () => {
    return section.map((group, j) => {
      const elements = group.components.map((component, k) => {
        const onUpdateValue = (newResponse) => {
          let existError = false;
          if (newResponse === undefined || newResponse === '' || newResponse?.fileinbase64 === null) {
            existError = true;
          }
          if (component.type === TEXTBOX) {
            if ((component.required === MANDATORY || !component.optional) && !checkDisabled(component) && newResponse === "") {
              existError = true;
            } else {
              existError = false;
            }
          }
          if (component.type === CHECKBOX) {
            if (component.required === MANDATORY && !newResponse) {
              existError = true;
            }
          }
          component.error = existError;
          component.response = newResponse;

          if (component.type === RADIOBUTTON) {
            if (component.required === MANDATORY && (newResponse === undefined || newResponse === null)) {
              existError = true;
            } else {
              component.selectedchoice = newResponse;
            }
          }
          const clonedForm = Helper.clone(formTransform);
          clonedForm[currentSection][j]["components"][k] = component;
          setFormTransform(clonedForm);
        };

        return (
          <Fragment key={k}>
            {component.type === TEXTBOX && (
              <TextBox
                key={k}
                componentData={component}
                onUpdateValue={onUpdateValue}
              />
            )}
            {component.type === CHECKBOX &&
              component.appearance === RADIOBUTTON && (
                <Checkbox
                  key={k}
                  onUpdateValue={onUpdateValue}
                  componentData={component}
                  appearance="radiobutton"
                />
              )}
            {component.type === CHECKBOX &&
              component.appearance === CHECKBOX && (
                <Checkbox
                  key={k}
                  onUpdateValue={onUpdateValue}
                  componentData={component}
                  appearance="checkbox"
                />
              )}
            {component.type === RADIOBUTTON && (
              <RadioButton
                key={k}
                componentData={component}
                onUpdateValue={onUpdateValue}
              />
            )}
            {component.type === ATTACHMENT && (
              <Attachment
                key={k}
                componentData={component}
                onUpdateValue={onUpdateValue}
              />
            )}
          </Fragment>
        );
      });
      return (
        <Fragment key={j}>
          <FormTitle title={group.title}></FormTitle>
          {elements}
        </Fragment>
      );
    });
  };

  return renderSection();
};