import React, { useContext, useEffect, useState } from 'react';
import "./Home.css";
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { IS_FROM_BATCH_LIST, SHOW_ONBOARDING_PAGE } from '../../constants/GeneralConstants';
import { useNavigate, useLocation } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from 'react-device-detect';
import { BatchContext } from '../../contexts/BatchContext';
import { ViewDocumentIcon } from '../../components/SvgComponents/ViewDocumentIcon/ViewDocumentIcon';
import { SmsDocumentIcon } from '../../components/SvgComponents/SmsDocumentIcon/SmsDocumentIcon';
import { DrawDocumentIcon } from '../../components/SvgComponents/DrawDocumentIcon/DrawDocumentIcon';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';


export const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [showOnboardingPage, setShowOnboardingPage] = useState(localStorage.getItem(SHOW_ONBOARDING_PAGE));
  const [flowSteps, setFlowSteps] = useState({});

  const documentsState = location.state.documents;
  const isMultipleDocs = Array.isArray(documentsState);

  const { addDocumentsToBatch } = useContext(BatchContext);

  var settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  // On Init
  useEffect(() => {
    const document = isMultipleDocs ? documentsState[0] : documentsState;
    localStorage.setItem(IS_FROM_BATCH_LIST, "false");
    setFlowSteps({ skipSMS: document.SignerDTO.SkipSMS, skipSigDraw: document.SignerDTO.SkipSigDraw });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewDocument = () => {
    const emailId = location.state.emailId;

    // Check if is batch flow
    if (isMultipleDocs) {
      addDocumentsToBatch(documentsState);
      localStorage.setItem(IS_FROM_BATCH_LIST, "true");
      navigate('/documents', {
        state: {
          emailId
        }
      });
      return;
    }

    // Single document flow
    const document = documentsState;

    const newPageState = {
      state: {
        documents: document,
        emailId
      }
    };

    let link = "/document";

    if (document.SignerDTO.Form && document.SignerDTO.Form.FormDisplay.toLowerCase() === "beforepreview") link = "/questions";
    if (document.SignerDTO.Form && document.SignerDTO.Form.FormDisplay.toLowerCase() === "onpreview") link = "/document-questions";

    navigate(link, newPageState);
  }

  const handleShowHomePageChange = (e) => {
    let value = e.target.value;
    if (value === "true") {
      value = "false";
      setShowOnboardingPage(value);
      localStorage.setItem(SHOW_ONBOARDING_PAGE, value);
      return;
    }

    if (value === "false") {
      value = "true";
      setShowOnboardingPage(value);
      localStorage.setItem(SHOW_ONBOARDING_PAGE, value);
    }
  }

  return (
    <>
      {!isMobile && (
        <Container>
          <Row className="container-row row-box">
            <Col>
              <span className="title">{t('home.subtitle')}</span>
            </Col>
            <div
              className={`row row-cols-1 ${!flowSteps.skipSigDraw && !flowSteps.skipSMS ? 'row-cols-md-3' : 'row-cols-md-2'
                } g-4 justify-content-center`}
              style={{ marginTop: '0px' }}
            >
              <div className="col height-card">
                <div className="card h-100">
                  <ViewDocumentIcon />
                  <div className="card-body">
                    <h5 className="card-title">{t('home.content1.title')}</h5>
                    <p className="card-text">{t('home.content1.description')}</p>
                  </div>
                </div>
              </div>
              {!flowSteps.skipSMS && (
                <div className="col height-card">
                  <div className="card h-100">
                    <SmsDocumentIcon />
                    <div className="card-body">
                      <h5 className="card-title">{t('home.content2.title')}</h5>
                      <p className="card-text">{t('home.content2.description')}</p>
                    </div>
                  </div>
                </div>
              )}
              {!flowSteps.skipSigDraw && (
                <div className="col height-card">
                  <div className="card h-100">
                    <DrawDocumentIcon />
                    <div className="card-body">
                      <h5 className="card-title">{t('home.content3.title')}</h5>
                      <p className="card-text">{t('home.content3.description')}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Row>
          <Row className="footer-onboarding-page-container">
            <Col md={2}></Col>
            <Col md={8}>
              <button
                type="button"
                className="btn btn-primary btn-active"
                onClick={handleViewDocument}
              >
                <Row>
                  <Col className="text-align-start">{t('general.start')}</Col>
                  <Col className="text-align-end">
                    <ArrowIcon />
                  </Col>
                </Row>
              </button>
            </Col>
          </Row>
          <Row className="footer-onboarding-page-container">
            <Col md={2}></Col>
            <Col md={8}>
              <Form>
                <Form.Check type="checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    value={showOnboardingPage}
                    onChange={handleShowHomePageChange}
                  />
                  <Form.Check.Label>{t('home.accept')}</Form.Check.Label>
                </Form.Check>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
      {isMobile &&
        <>
          <Container>
            <Row className="container-row row-box">
              <Col>
                <span className="title">{t('home.subtitle')}</span>
              </Col>
              <Slider {...settings}>
                <div className="col height-card">
                  <div className="card h-100">
                    <ViewDocumentIcon />
                    <div className="card-body">
                      <h5 className="card-title">{t('home.content1.title')}</h5>
                      <p className="card-text">{t('home.content1.description')}</p>
                    </div>
                  </div>
                </div>
                {
                  !flowSteps.skipSMS &&
                  <div className="col height-card">
                    <div className="card h-100">
                      <SmsDocumentIcon />
                      <div className="card-body">
                        <h5 className="card-title">{t('home.content2.title')}</h5>
                        <p className="card-text">{t('home.content2.description')}</p>
                      </div>
                    </div>
                  </div>
                }
                {
                  !flowSteps.skipSigDraw &&
                  <div className="col height-card">
                    <div className="card h-100">
                      <DrawDocumentIcon />
                      <div className="card-body">
                        <h5 className="card-title">{t('home.content3.title')}</h5>
                        <p className="card-text">{t('home.content3.description')}</p>
                      </div>
                    </div>
                  </div>
                }
              </Slider>
            </Row>
            <Row className="footer-onboarding-page-container-mobile">
              <Col md={2}></Col>
              <Col md={8}>
                <button type="button" className="btn btn-primary btn-active" onClick={handleViewDocument}>
                  <Row>
                    <Col className="text-align-start">
                      {t('general.start')}
                    </Col>
                    <Col className="text-align-end">
                      <ArrowIcon />
                    </Col>
                  </Row>
                </button>
              </Col>
            </Row>
            <Row className="footer-onboarding-page-container">
              <Col md={2}></Col>
              <Col md={8}>
                <Form>
                  <Form.Check type="checkbox" style={{ display: 'inline-flex', alignItems: 'center', paddingLeft: '1.5em' }}>
                    <Form.Check.Input type="checkbox" value={showOnboardingPage} onChange={handleShowHomePageChange} />
                    <Form.Check.Label>{t('home.accept')}</Form.Check.Label>
                  </Form.Check>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
      }
    </>
  );
}

