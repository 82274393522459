import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationAR from '../assets/i18n/ar';
import translationBR from '../assets/i18n/br';
import translationCA from '../assets/i18n/ca';
import translationDE from '../assets/i18n/de';
import translationES from '../assets/i18n/es';
import translationEN from '../assets/i18n/en';
import translationEU from '../assets/i18n/eu';
import translationFI from '../assets/i18n/fi';
import translationFR from '../assets/i18n/fr';
import translationGL from '../assets/i18n/gl';
import translationHU from '../assets/i18n/hu';
import translationIT from '../assets/i18n/it';
import translationKO from '../assets/i18n/ko';
import translationNL from '../assets/i18n/nl';
import translationNO from '../assets/i18n/no';
import translationPL from '../assets/i18n/pl';
import translationPT from '../assets/i18n/pt';
import translationRO from '../assets/i18n/ro';
import translationZH from '../assets/i18n/zh';

// the translations
const resources = {
    ar: {
        translation: translationAR
    },
    br: {
        translation: translationBR
    },
    ca: {
        translation: translationCA
    },
    de: {
        translation: translationDE
    },
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    },
    eu: {
        translation: translationEU
    },
    fi: {
        translation: translationFI
    },
    fr: {
        translation: translationFR
    },
    gl: {
        translation: translationGL
    },
    hu: {
        translation: translationHU
    },
    it: {
        translation: translationIT
    },
    ko: {
        translation: translationKO
    },
    nl: {
        translation: translationNL
    },
    no: {
        translation: translationNO
    },
    pl: {
        translation: translationPL
    },
    pt: {
        translation: translationPT
    },
    ro: {
        translation: translationRO
    },
    zh: {
        translation: translationZH
    }
};

const langaugeSelected = localStorage.getItem('i18nextLng');

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: langaugeSelected,
        fallbackLng: "en", // use en if detected lng is not available
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        // react-i18next options
        react: {
            wait: true
        }
    });

export default i18n;