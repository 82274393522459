import { Modal } from "antd";
import Helper from "../../../util/helper";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import './RGPDPolicyModal.css';
import { CheckIcon } from "../../SvgComponents/CheckIcon/CheckIcon";

export const RGPDPolicyModal = ({ rgpdText, rgpdFields, openModalProtection, setOpenModalProtection }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={rgpdText.Tittle}
            style={{ top: 100 }}
            width={1000}
            footer={null}
            open={openModalProtection}
            closable={true}
            onCancel={() => setOpenModalProtection(false)}
        >
            <div dangerouslySetInnerHTML={Helper.mapInnerHtmlData(rgpdText.Body)}></div>
            <div className="modal-container">
            <div className="content-fields">
                {rgpdFields}
            </div>
            <Col className="action-buttons mobile-button">
                <Row>
                <Col lg={4} md={4} xs={2}></Col>
                <Col lg={4} md={4} xs={8}>
                    <button
                    type="button"
                    className="btn btn-primary btn-active"
                    onClick={() => setOpenModalProtection(false)}
                    >
                        <span className="cancel-text">{t("general.ok")}</span>
                        <CheckIcon />
                    </button>
                </Col>
                <Col lg={4} md={4} xs={2}></Col>
                </Row>
            </Col>
            </div >
        </Modal >
    );
};