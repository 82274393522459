import { IS_CUSTOM } from "../constants/GeneralConstants";
import EmailSignatureService from "./EmailSignatureService";

/**
 * These variables should be the same as the DB default config, 
 * if we don´t want this hardcoded values we should call a service to get the DB config
 */
const PRIMARY_COLOR = "#00BF71";
const PRIMARY_TEXT_COLOR = "#FFFFFF";
const SECONDARY_COLOR = "#00BF71";
const SECONDARY_TEXT_COLOR = "#151A35";
const LOGO = "/logo_vidsigner_white.png";

const ThemeService = {
    setTheme: function (emailId) {
        if (!(window.CSS && window.CSS.supports)) {
            // CSS custom properties not supported
            return false;
        }
        getAppearanceConfig(emailId);
    },
    asyncSetTheme: async function (emailId) {
        if (!(window.CSS && window.CSS.supports)) {
            // CSS custom properties not supported
            return false;
        }
        await getAppearanceConfig(emailId);
    },
};

const setValues = (appearanceConfDTO) => {
    // //It's null when you logged out so set the default Validated ID styles to do not break the login view
    if (appearanceConfDTO == null || appearanceConfDTO.IsCustom === "false") {
        appearanceConfDTO = getDefaultValues();
    }

    const areValidatedIDColors = 
        appearanceConfDTO.PrimaryColor.toUpperCase() === "#00BF71" &&
        appearanceConfDTO.PrimaryTextColor.toUpperCase() === "#FFFFFF" &&
        appearanceConfDTO.SecondaryColor.toUpperCase() === "#00BF71" &&
        appearanceConfDTO.SecondaryTextColor.toUpperCase() === "#151A35";

    localStorage.setItem(IS_CUSTOM, (!areValidatedIDColors).toString());
    
    var DOMstyle = document.documentElement.style;
    DOMstyle.setProperty("--custom-primary-background-color", appearanceConfDTO.PrimaryColor);
    DOMstyle.setProperty("--custom-primary-text-color", appearanceConfDTO.PrimaryTextColor);
    DOMstyle.setProperty("--custom-secondary-background-color", appearanceConfDTO.SecondaryColor);
    DOMstyle.setProperty("--custom-secondary-text-color", appearanceConfDTO.SecondaryTextColor);
    DOMstyle.setProperty("--custom-primary-background-color-hover", adjustColor(appearanceConfDTO.PrimaryColor, -40));
    DOMstyle.setProperty("--custom-primary-background-color-opacity", hexToRgba(appearanceConfDTO.PrimaryColor, 0.1));
    DOMstyle.setProperty("--custom-logo", 'url("' + appearanceConfDTO.Logo + '")');
}

const getAppearanceConfig = (emailId) => {
    return EmailSignatureService.getAppearanceConfig(emailId)
        .then((response) => {
            setValues(response.data);
        })
        .catch(err => {
            console.log(err);
        });
}

function getDefaultValues() {
    return {
        PrimaryColor: PRIMARY_COLOR,
        PrimaryTextColor: PRIMARY_TEXT_COLOR,
        SecondaryColor: SECONDARY_COLOR,
        SecondaryTextColor: SECONDARY_TEXT_COLOR,
        Logo: LOGO
    };
}

const adjustColor = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
};

function hexToRgba(hex, opacity) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export default ThemeService;