import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./FinalizedProcess.css";
import { SignatureCompletionImage } from "../../components/SvgComponents/SignatureCompletionImage/SignatureCompletionImage";

export const FinalizedProcess = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Col md={2}></Col>
        <Col md={8} xs={12} className="justify-content-center container-box">
          <Row style={{ display: "flex", flexDirection: "column" }}>
            <SignatureCompletionImage />
            <hr className="success-hr" />
            <p>
              {t("popup_process.title_finish")}
            </p>
            <span className="green-text">
              {t("popup_process.subtitle")}
            </span>
          </Row>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
};
