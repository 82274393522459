import { generateBasicAuthHeaders, generateOAuthRequestHeaders } from './generateHeaders';
import { axiosInstance } from './../services/RemoteService';

const MANAGEMENT_BASE_URI =
  process.env.REACT_APP_MANAGEMENT_BASE_URI;

export const managementGetRequest = async (url) => {
  const httpOptions = generateBasicAuthHeaders();
  try {
    const response = await axiosInstance.get(MANAGEMENT_BASE_URI + url, httpOptions);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const managementWithBearerGetRequest = (url, token) => {
  const httpOptions = generateOAuthRequestHeaders(token);

  return axiosInstance.get(MANAGEMENT_BASE_URI + url, httpOptions);
};

export const managementPostRequest = (url, body) => {
  const httpOptions = generateBasicAuthHeaders();

  return axiosInstance.post(MANAGEMENT_BASE_URI + url, body, httpOptions);
};

export const managementWithBearerPostRequest = (url, body, token) => {
  const httpOptions = generateOAuthRequestHeaders(token);

  return axiosInstance.post(MANAGEMENT_BASE_URI + url, body, httpOptions);
};

export const managementPutRequest = (url, body) => {
  return axiosInstance.put(MANAGEMENT_BASE_URI + url, body);
};

export const managementDeleteRequest = (url) => {
  return axiosInstance.delete(MANAGEMENT_BASE_URI + url);
};