import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';

import "./SignatureWithBatchSuccess.css";
import { SignatureCompletionImage } from "../../components/SvgComponents/SignatureCompletionImage/SignatureCompletionImage";
import { useContext } from "react";
import { BatchContext } from "../../contexts/BatchContext";
import { SuccessSignatureHeader } from "../../components/SuccessSignatureHeader/SuccessSignatureHeader";
import { PoweredByValidatedID } from "../../components/PoweredByValidatedID/PoweredByValidatedID";
import { ArrowIcon } from "../../components/SvgComponents/ArrowIcon/ArrowIcon";

export const SignatureWithBatchSuccess = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const emailId = location.state.emailId;

  const { remainingBatchDocuments } = useContext(BatchContext);

  const goToPendingDocuments = () => {
    navigate("/documents", {
      state: {
        emailId,
      }
    });
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={2} xs={1}></Col>
          <Col md={8} xs={12} className="justify-content-center padding-content">
            <SuccessSignatureHeader />
            <Row className="container-box signature-success" style={{ display: "flex", flexDirection: "column" }}>
              <SignatureCompletionImage />
              <h2 className="rejected-title">{t("sign.sign_complete_title")}</h2>
              <p className="rejected-subtitle">{t("popup_process.subtitle")}</p>
              <hr className="success-hr" />
              <p>
                {t("popup_process.pending_documents_1")}{" "}
                <span className="green-text" onClick={goToPendingDocuments} style={{cursor: 'pointer'}}>
                  {t("popup_process.pending_documents_2", { pending: remainingBatchDocuments })}
                </span>{" "}
                {t("popup_process.pending_documents_3")}
              </p>
            </Row>
            <Row className="delegation-button action-buttons mobile-button">
              <Col md={1} lg={3} xs={1}></Col>
              <Col md={10} lg={6} xs={12}>
                <button type="button" className="btn btn-primary btn-active" onClick={goToPendingDocuments}>
                  <span className="cancel-text"> {t("general.continue")} </span>
                  <ArrowIcon />
                </button>
              </Col>
              <Col md={1} lg={3} xs={1}></Col>
            </Row>
            <PoweredByValidatedID />
          </Col>
          <Col md={2} xs={1}></Col>
        </Row>
      </Container>
    </>
  );
};
