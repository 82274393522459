import { privGetRequest, privPostRequest } from "../vidCloud/priv";

const LocalSignatureService = {};

LocalSignatureService.sendCertificateCalculatePreSignature = (signerGuid, body) => {

  return privPostRequest(`/localsignature/${signerGuid}/certificate`, body);
}

LocalSignatureService.sendSignatureResult = (signerGuid, body) => {

  return privPostRequest(`/localsignature/${signerGuid}`, body);
}

LocalSignatureService.getSignatureHash = (signerGuid) => {

  return privGetRequest(`/presignature?SignatureId=${signerGuid}`);
}

export default LocalSignatureService;