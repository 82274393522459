import Helper from "../../util/helper";

import { ATTACHMENT, CHECKBOX, MANDATORY, RADIOBUTTON, TEXTBOX } from '../../constants/GeneralConstants';

import { TextBox } from "../TextBox/TextBox";
import { RadioButton } from "../RadioButton/RadioButton";
import { Checkbox } from "../Checkbox/Checkbox";
import { Attachment } from "../Attachment/Attachment";
import { Fragment, useContext } from "react";
import { FormContext } from "../../contexts/FormContext";
import { FormTitle } from "../FormTitle/FormTitle";

export const AdvancedFormGroup = ({ index, pagesLength }) => {
    const { formTransform, setFormTransform, getComponentOriginalPosition, setMaxPageWithQuestions } = useContext(FormContext);
    const { pageData: group, maxPageWithQuestions }  = Helper.getAdvancedFormInfo(formTransform, index, pagesLength);

    setMaxPageWithQuestions(maxPageWithQuestions);

    const elements = group.components.map((component, k) => {
      const onUpdateValue = (newResponse) => {
        let existError = false;
        if (newResponse === undefined || newResponse === '' || newResponse?.fileinbase64 === null) {
          existError = true;
        }
        if (component.type === TEXTBOX) {
          if ((component.required === MANDATORY || !component?.optional) && newResponse === "") {
            existError = true;
          } else {
            existError = false;
          }
        }
        component.error = existError;
        component.response = newResponse;
        if (component.type === RADIOBUTTON) {
          if (component.required === MANDATORY && (newResponse === undefined || newResponse === null)) {
            existError = true;
          } else {
            component.selectedchoice = newResponse;
          }
        }
        const clonedForm = Helper.clone(formTransform);

        const { sectionIndex, groupIndex, componentIndex } = getComponentOriginalPosition(component.id);
        
        clonedForm[sectionIndex][groupIndex]["components"][componentIndex] = component;

        setFormTransform(clonedForm);
      };

      return (
        <Fragment key={k}>
          {component.type === TEXTBOX && (
            <TextBox
              key={k}
              componentData={component}
              onUpdateValue={onUpdateValue}
              isAdvancedForm
            />
          )}
          {component.type === CHECKBOX && (
              <Checkbox
                key={k}
                onUpdateValue={onUpdateValue}
                componentData={component}
                appearance="checkbox"
                isAdvancedForm
              />
            )}
          {component.type === RADIOBUTTON && (
            <RadioButton
              key={k}
              componentData={component}
              onUpdateValue={onUpdateValue}
              isAdvancedForm
            />
          )}
          {component.type === ATTACHMENT && (
            <Attachment
              key={k}
              componentData={component}
              onUpdateValue={onUpdateValue}
              isAdvancedForm
            />
          )}
        </Fragment>
      );
    })

    return <Fragment>
    {
      group.title.map((titleElement, titleIndex) => 
        <FormTitle key={titleIndex} isAdvancedForm title={titleElement}></FormTitle>
      )
    }
    {elements}
  </Fragment>;
}