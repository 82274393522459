import { useContext, useEffect, useState } from "react";
import { FormContext } from "../../contexts/FormContext";
import { DocumentPreview } from "../DocumentPreview/DocumentPreview";
import { useLocation } from "react-router-dom";
import Helper from "../../util/helper";
import { isMobile, isTablet } from "react-device-detect";
import { useOrientation } from "../../hooks/useOrientation";
import { AllowRotationModal } from "../../components/Modals/AllowRotationModal/AllowRotationModal";

export const OnPreviewPage = () => {
  const location = useLocation();
  const [allowRotationModal, setAllowRotationModal] = useState(false);
  const [initialOrientation, setInitialOrientation] = useState("");
  const { isLandscape, isPortrait } = useOrientation();

  const document = location.state.documents;
  const { form, setForm, areQuestionsAnswered, setFormTransform, formTransform } =
    useContext(FormContext);

  useEffect(() => {
    setForm(document.SignerDTO.Form);
    const formData = document.SignerDTO.Form;

    const clonedForm = Helper.clone(formData);
    const transformedForm = Helper.transformAdvancedFormData(clonedForm);
    setFormTransform(transformedForm);

    if (isMobile || isTablet) {
      setInitialOrientation(isLandscape ? "landscape" : "portrait");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMobile || isTablet) {
      const showModal = (initialOrientation === "portrait" && isLandscape) || (initialOrientation === "landscape" && isPortrait);

      setAllowRotationModal(showModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLandscape, isPortrait]);

  return <>
    <DocumentPreview areQuestionsAnswered={areQuestionsAnswered} form={form} formTransform={formTransform} />
    <AllowRotationModal source="onpreview" allowRotationModal={allowRotationModal} />
  </>;
};
