import React, { useEffect, useState } from 'react';
import "./SignatureReasonIcon.css";

export const SignatureReasonIcon = () => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-primary-background-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="signature-icon">
        <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M32 9.0656L32 22.936C32 28.3818 28.1131 32 22.5344 32H9.4656C3.89273 32 0 28.371 0 22.936L0 9.0656C0 3.61954 3.8872 0 9.4656 0L22.5344 0C28.1128 0 32 3.61954 32 9.0656ZM29.5998 22.9359L29.5998 9.06554C29.5998 4.98474 26.8238 2.39994 22.5342 2.39994L9.46536 2.39994C5.1757 2.39994 2.39976 4.98474 2.39976 9.06554L2.39976 22.9359C2.39976 27.0064 5.18181 29.5999 9.46536 29.5999H22.5342C26.8243 29.5999 29.5998 27.0163 29.5998 22.9359Z" fill={color} />
        <path d="M15.5192 6C10.2631 6 6 10.2631 6 15.5192C6 20.7754 10.2631 25.0385 15.5192 25.0385C20.7754 25.0385 25.0385 20.7754 25.0385 15.5192C25.0385 10.2631 20.7754 6 15.5192 6Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.5192 19.8461V13.7885" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.7885 19.8462H17.25" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.7885 14.6538L15.5192 13.7885" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.5192 11.1923V10.3269" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </>
  );
}