import React, { Fragment, createRef, useContext, useRef } from 'react';
import "./ImageViewer.css";

import { AdvancedFormGroup } from '../AdvancedFormGroup/AdvancedFormGroup';
import { RenderScrollContext } from '../../contexts/RenderScrollContext';
import { DOCUMENT_PAGE_VIEW } from '../../constants/GeneralConstants';

export const ImageViewer = (props) => {

  const { images, form } = props;
  const { page, setPage } = useContext(RenderScrollContext);
  const lineRefs = useRef([]);

  lineRefs.current = images?.map((_, i) => lineRefs.current[i] ?? createRef());

  const changePage = (newPage) => {
    setPage({
      value: newPage,
      source: DOCUMENT_PAGE_VIEW});
  };

  const imagesList = images?.map((renderedImage, index) => {
    return (
      <Fragment key={index}>
        <div className={form ? "page-image-on-preview" : "page-image"}>
          <img
            ref={lineRefs.current[index]}
            key={index}
            alt="renderedImage"
            src={`data:image/jpeg;base64,${renderedImage}`}
            className={form ? "pdf-images-on-preview" : "pdf-images"}
            id={`pdf-page-${index + 1}`}
            style={{ ...props.zoomStyles }}
            onClick={() => changePage(index)}
          />
          {
            form && <AdvancedFormGroup index={index} pagesLength={images.length} />
          }
        </div>
      </Fragment>
    )
  });

  return (
    <>
      {imagesList}
    </>
  );
}