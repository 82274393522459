import { IS_OPENED_FROM_DOCUMENTS_LIST } from "../constants/GeneralConstants";
import { privGetRequest } from "../vidCloud/priv";

const PendingDocumentService = {};

/**
 * 
 * @param {*} emailId 
 * @returns pending documents list
 */
PendingDocumentService.getPendingDocumentsList = (emailId) => {
  return privGetRequest(`/emailsignatures/${emailId}/nextdocuments`);
}

/**
 * 
 * @param {*} IsOpenedFromDocumentList 
 */
PendingDocumentService.setIsOpenedFromDocumentList = (IsOpenedFromDocumentList) => {
  localStorage.setItem(IS_OPENED_FROM_DOCUMENTS_LIST, IsOpenedFromDocumentList);
}

/**
 * 
 * @returns if is opened from document list
 */
PendingDocumentService.getIsOpenedFromDocumentList = () => {
  return localStorage.getItem(IS_OPENED_FROM_DOCUMENTS_LIST);
}

export default PendingDocumentService;