import { privGetRequest, privPutRequest } from "../vidCloud/priv";

const PersonalCodeService = {};

PersonalCodeService.getAccessWithPersonalCode = (emailId) => {
  return privGetRequest(`/emailsignatures/${emailId}/configuration`);
}

PersonalCodeService.putValidatePersonalCodeRequest = (emailId, body) => {
  return privPutRequest(`/emailsignatures/${emailId}/personalcode`, body);
}

export default PersonalCodeService;