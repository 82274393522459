import { privDeleteRequest, privGetRequest } from "../vidCloud/priv";

const DocumentService = {};

DocumentService.documentRendered = (docGuid, pageNumber) => {

  return privGetRequest(`/documentrendered/${docGuid}/pages/${pageNumber}`);

}

DocumentService.deleteDocumentRendered = (docGuid) => {

  return privDeleteRequest(`/documentrendered/${docGuid}`);
}

export default DocumentService;