import React, { useContext, useEffect, useState } from 'react';
import "./SendOtpSms.css";
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import EmailSignatureService from '../../services/EmailSignatureService';
import { Loader } from '../../components/Loader/Loader';
import { FIRST_LOAD } from '../../constants/GeneralConstants';
import { isDesktop, isMobile } from 'react-device-detect';
import { OTP } from '../../components/SignMethods/OTP/OTP';
import { BatchContext } from '../../contexts/BatchContext';
import { Back } from '../../components/Back/Back';
import { SMSConfirmationModal } from '../../components/Modals/SMSConfirmationModal/SMSConfirmationModal';
import { SmsSendingIcon } from '../../components/SvgComponents/SmsSendingIcon/SmsSendingIcon';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { AudioIcon } from '../../components/SvgComponents/AudioIcon/AudioIcon';

export const SendOtpSms = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [openModalPhoneNumberConfirmation, setOpenModalPhoneNumberConfirmation] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [otpCode, setOtpCode] = useState('');
  const [emailId, setEmailId] = useState('');
  const [showOtpErrorMessage, setShowOtpErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newCodeLabel, setNewCodeLabel] = useState(true);
  const [newCodeAudioLabel, setNewCodeAudioLabel] = useState(false);
  const [supportLabel, setSupportLabel] = useState(false);
  const [otpFlow, setOTPFlow] = useState(false);
  const documentsState = location.state.documents;
  const requestId = location.state.requestId;
  const documents = Array.isArray(documentsState) ? documentsState[0] : documentsState;
  const email = documents.SignerDTO.eMail;
  const [widthIcon, setWidthIcon] = useState(150);
  const [heightIcon, setHeightIcon] = useState(147);

  const { remainingBatchDocuments, setBatchOTP, batchAttachedImages, isBatchMultisignature } = useContext(BatchContext);

  // On Init
  useEffect(() => {
    setPhoneNumber(location.state.phoneNumber);
    setEmailId(location.state.emailId);
    if (isMobile) {
      setWidthIcon(120);
      setHeightIcon(115);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowCustomPhoneNumber = () => {
    setOpenModalPhoneNumberConfirmation(false);
  }

  const handleBack = () => {
    localStorage.setItem(FIRST_LOAD, "true");
    if (location.state?.isPhoneNumberCustom) {
      const link = '/customsms';
      navigate(link, {
        state: {
          documents,
          emailId
        }
      });
      return;
    }

    let link = '/document';
    if (documents.SignerDTO.Form && documents.SignerDTO.Form.FormDisplay.toLowerCase() === "onpreview") link = "/document-questions";

    if (isBatchMultisignature) link = '/documents';

    navigate(link, {
      state: {
        documents,
        emailId
      }
    });
  }

  const handleSendSmsCode = () => {
    setLoading(true);
    const body = {
      "OTP": otpCode
    };

    if (documents.SignerDTO.OneShot) {
      let link = "/sign";
      if (documents.SignerDTO.AttachIdDocImages && typeof attachedImages === 'undefined' && !batchAttachedImages) {
        link = "/attach-images";
      }

      navigate(link, {
        state: {
          documents,
          emailId,
          otpCode,
          requestId
        }
      });

      return;
    }
    EmailSignatureService.sendOTPCode(emailId, body)
      .then(response => {
        if (response) {
          setLoading(false);
          if (remainingBatchDocuments > 0) setBatchOTP(otpCode);

          const { SkipSigDraw } = documents.SignerDTO;

          // OTP Flow
          if (SkipSigDraw && !documents.SignerDTO.AttachIdDocImages) return setOTPFlow(true);

          let link = '/sign';
          if (documents.SignerDTO.AttachIdDocImages && !batchAttachedImages) link = "/attach-images";

          navigate(link, {
            state: {
              documents,
              emailId,
              otpCode,
            }
          });
          return;
        }
        setLoading(false);
        setShowOtpErrorMessage(true);
      })
      .catch(err => {
        setLoading(false);
        setShowOtpErrorMessage(true);
        console.log(err);
      });
  };

  const handlerNewCode = () => {
    setLoading(true);
    const body = {
      "PhoneNumber": phoneNumber
    };
    EmailSignatureService.resendOtpSms(emailId, body)
      .then(response => {
        setLoading(false);
        setNewCodeLabel(false);
        setNewCodeAudioLabel(true);
      })
      .catch(err => {
        setShowOtpErrorMessage(true);
        setLoading(false);
        console.log(err);
      });
  };

  const handlerNewCodeAudio = () => {
    setLoading(true);
    const body = {
      "PhoneNumber": phoneNumber
    };
    EmailSignatureService.otpCall(emailId, body)
      .then(response => {
        setLoading(false);
        setNewCodeLabel(false);
        setNewCodeAudioLabel(false);
        setSupportLabel(true);
      })
      .catch(err => {
        setShowOtpErrorMessage(true);
        setLoading(false);
        console.log(err);
      });
  };

  const handlerSupportTicket = () => {
    setLoading(true);
    navigate("/support", {
      state: {
        documents: location.state.documents,
        phoneNumber: phoneNumber,
        emailId: emailId
      }
    });
  };

  return (
    <>
      {loading && <Loader />}
      {otpFlow && <OTP signerGuid={documents.SignerDTO.SignerGUI} otpCode={otpCode} emailId={emailId} docGuid={documents.DocGUI} email={email} setLoadingMorePages={setLoading}/>}
      {!loading &&
        <Container className="document-preview-container">
          <Row>
            <Col md={12} lg={12} className="document-preview-title">
              <Back handleBack={handleBack} />
            </Col>
            <Col md={2} xs={1}></Col>
            <Col md={12} xs={10} lg={12} className="justify-content-center container-sms-box-2">
              <Row>
                <Col md={4} lg={4}>
                  <SmsSendingIcon width={widthIcon} height={heightIcon} />
                </Col>
                <Col md={8} lg={5} className="col-align">
                  <h4 className="sms-title">{t('sms.title')}</h4><br></br>
                  <p className="sms-description">{t('sms.description')}</p>
                  <Form className="phone-number-form">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control className="phone-number-input-disabled" type="text" value={phoneNumber} disabled />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control className="phone-number-input" isInvalid={`${showOtpErrorMessage ? 'error' : ''}`} type={isDesktop ? "text" : "number"} placeholder={t('personal_code.enter_the_code')} name="otpCode" value={otpCode} onChange={e => setOtpCode(e.target.value)} />
                    </Form.Group>
                  </Form>
                  {
                    showOtpErrorMessage &&
                    <div className="error-message align-error-message">
                      {t('sms.invalid_code')}
                    </div>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={0}></Col>
            <Col md={4} xs={12} className="button-margin button-only">
              <button type="button" className="btn btn-primary btn-active phone-number-button" disabled={otpCode.length === 0} onClick={handleSendSmsCode}>
                <span className="cancel-text">{t('general.sign')}</span>
                <ArrowIcon disabled={otpCode.length === 0}/>
              </button>
            </Col>
          </Row>
          <Row>
            <Col><br></br>
              {newCodeLabel &&
                <div>
                  <h6 className="new-code-label" onClick={() => handlerNewCode()}>{t('sms.new_code')}</h6>
                  <p className="new-code-message">{t('sms.request_code')}</p>
                </div>
              }
              {newCodeAudioLabel &&
                <div className="audio-label">
                  <span onClick={() => handlerNewCodeAudio()}>{t('sms.new_code_call')}&nbsp;&nbsp;
                    <AudioIcon />
                  </span>
                </div>
              }
              {supportLabel &&
                <div className="audio-label">
                  <span onClick={() => handlerSupportTicket()}>{t('sms.support')}</span>
                </div>
              }
            </Col>
          </Row>
          <SMSConfirmationModal openModalPhoneNumberConfirmation={openModalPhoneNumberConfirmation} setOpenModalPhoneNumberConfirmation={setOpenModalPhoneNumberConfirmation} handleShowCustomPhoneNumber={handleShowCustomPhoneNumber} phoneNumber={phoneNumber}></SMSConfirmationModal>
        </Container>
      }
    </>
  );
}

