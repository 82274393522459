import React, { useEffect, useState } from 'react';

export const Back2Icon = () => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-secondary-text-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.30887 11.6177L2.00002 6.30886M2.00002 6.30886L7.30884 0.999969M2.00002 6.30886L12.5002 6.30908" stroke={color} strokeWidth="2" />
      </svg>
    </>
  );
}