import { Col, Row } from "react-bootstrap";
import cancel from "./../../../assets/icons/cancel.svg";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import EmailSignatureService from "../../../services/EmailSignatureService";
import { useState } from "react";
import { ArrowIcon } from "../../SvgComponents/ArrowIcon/ArrowIcon";

export const CriiptoModal = ({
  openCriiptoModal,
  setOpenCriiptoModal,
  signerGuid,
  criiptoProvider,
  setParentLoading,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleContinueCriipto = () => {
    setLoading(true);
    EmailSignatureService.getCriiptoUrl(signerGuid)
    .then((response) => {
        const { Url } = response.data;
        
        window.location.href = Url;
    });
  }

  const handleCancel = () => {
    setOpenCriiptoModal(false);

    setParentLoading(false);
  }

  return (
    <Modal
      title=""
      style={{ top: 150 }}
      footer={null}
      open={openCriiptoModal}
      closable={true}
      onCancel={handleCancel}
    >
      <div className="modal-container">
        <strong><p>{t("sign.auth_provider_title", { provider : 'Criipto'})}</p></strong>
        <p className="nu-input-label">{t("sign.auth_provider_description", { provider : criiptoProvider?.Name})}</p>
        <Row className="action-buttons mobile-button document-preview-buttons">
            <Col md={6} xs={6}>
              <button
                type="button"
                className="btn btn-secondary btn-cancel"
                onClick={handleCancel}
              >
                <span className="document-preview-text">{t("general.cancel")}</span>
                <img className="document-icon-image" src={cancel} alt="Check" />
              </button>
            </Col>
            <Col md={6} xs={6}>
              <button
                disabled={loading}
                type="button"
                className="btn btn-primary btn-active"
                onClick={handleContinueCriipto}
              >
                <span className="document-preview-text">{t("general.continue")}</span>
                <ArrowIcon disabled={loading} />
              </button>
            </Col>
        </Row>
      </div>
    </Modal>
  );
};
