import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import error from "./../../assets/svg/error.svg";

export const DocumentError = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row className="container-row">
        <Col md={2}></Col>
        <Col md={8} xs={12} className="justify-content-center container-box">
          <Row style={{ display: "flex", flexDirection: "column" }}>
            <img src={error} alt="error" style={{ width: 'auto', height: 'auto'}} />
            <p style={{ fontSize: "28px", fontWeight: "900", lineHeight: "30px", marginTop: "10%" }}>
              {t("document_error.title")}
            </p>
            <span> {t("document_error.description")} </span>
          </Row>
        </Col>
        <Col md={2}></Col>
      </Row >
    </Container >
  );
};
