import React, { useEffect, useState } from 'react';

export const DownloadDocumentIcon = () => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-primary-background-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M32 9.0656L32 22.936C32 28.3818 28.1131 32 22.5344 32H9.4656C3.89273 32 0 28.371 0 22.936L0 9.0656C0 3.61954 3.8872 0 9.4656 0L22.5344 0C28.1128 0 32 3.61954 32 9.0656ZM29.5998 22.9359L29.5998 9.06554C29.5998 4.98474 26.8238 2.39994 22.5342 2.39994L9.46536 2.39994C5.1757 2.39994 2.39976 4.98474 2.39976 9.06554L2.39976 22.9359C2.39976 27.0064 5.18181 29.5999 9.46536 29.5999H22.5342C26.8243 29.5999 29.5998 27.0163 29.5998 22.9359Z" fill={color} />
        <path d="M20.9292 13.4111L15.9181 18.4222M15.9181 18.4222L10.907 13.4111M15.9181 18.4222V8.40004" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <path d="M10.7993 24L21.5993 24" stroke={color} strokeWidth="2" strokeLinecap="round" />
      </svg>
    </>
  );
}