import { Col, Container, Row } from "react-bootstrap";
import errorImage from "./../../assets/svg/error.svg";
import { useTranslation } from "react-i18next";

export const ExpiredPersonalCode = () => {
  const { t } = useTranslation();

  return (
    <Container className="container-row row-box">
      <Row>
        <Col md={2}></Col>
        <Col md={8} xs={12} className="justify-content-center container-box">
          <Row>
            <img className="rejected-image" src={errorImage} alt="Error" />
            <h2 className="rejected-title">{t("personal_code.expired")}</h2>
          </Row>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
};
