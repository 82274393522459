import React, { createContext, useState } from 'react';

export const RenderScrollContext = createContext();

export const RenderScrollContextProvider = ({ children }) => {
  const [page, setPage] = useState({ value: 0, source: null });

  return (
    <RenderScrollContext.Provider value={{ page, setPage }}>
      {children}
    </RenderScrollContext.Provider>
  );
};