import React, { useEffect, useState } from 'react';

export const SimpleArrow = () => {

  const [isArabic, setIsArabic] = useState(false);

  // On Init
  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng');
    console.log("lang: ", lang);
    setIsArabic(lang === 'ar');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isArabic ? (
        <svg width="17" height="26" viewBox="0 0 17 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.5792 2L2.28955 13.2975L13.5792 24.595" stroke="black" strokeWidth="4" />
        </svg>
      ) : (
        <svg width="17" height="26" viewBox="0 0 17 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.28955 2L13.5792 13.2975L2.28955 24.595" stroke="black" strokeWidth="4" />
        </svg>
      )}
    </>
  );
}