import { useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FormContext } from "../../contexts/FormContext";
import Helper from "../../util/helper";
import './Choice.css';

export const Choice = ({ name, label, choiceIndex, parentData, onUpdateValue, isAdvancedForm, position, isDisabled }) => {
  const { isHTML, getScreenDimensions } = useContext(FormContext);

  const hideBorders = isAdvancedForm && parentData.hideborders;
  const handleChange = () => {
    onUpdateValue(choiceIndex);
  };

  const getPositionStyles = () => {
    const { fontFamily } = getScreenDimensions();

    const { posx, posy, sizex } = position;
    return Helper.getElementPositionStylesNew(posx, posy, sizex, fontFamily);
  }

  useEffect(() => {
    if (isDisabled) {
      onUpdateValue(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled])

  useEffect(() => {
    onUpdateValue(null);
  }, []);

  return isHTML ? (
    <div className="radiobutton-html-container" style={isAdvancedForm ? getPositionStyles() : {}}>
      <Form.Check className={`${hideBorders ? 'hide-radiobutton-borders' : ''}`} type="radio" id={label} name={name} onChange={handleChange} checked={parentData.selectedchoice === choiceIndex} label={<div dangerouslySetInnerHTML={Helper.mapInnerHtmlData(label)} />} disabled={isDisabled} />
    </div>
  ) : (
    <Form.Check style={isAdvancedForm ? getPositionStyles() : {}}
      className={`${hideBorders ? 'hide-radiobutton-borders' : ''}`}
      type="radio"
      id={label}
      name={name}
      label={label}
      onChange={handleChange}
      checked={isAdvancedForm ? undefined : parentData.selectedchoice === choiceIndex}
      disabled={isDisabled}
    />
  );
};
