import { privGetRequest, privPostRequest, privPutRequest } from "../vidCloud/priv";

const OneShotService = {};

OneShotService.createRequest = (signerGuid) => {

  return privPostRequest(`/emailsignatures/${signerGuid}/oneshot`, '');
}

OneShotService.sendOTP = (signerGuid, requestId) => {

  return privPutRequest(`/emailsignatures/${signerGuid}/oneshot/${requestId}/otp`, '');
}

OneShotService.getContract = (signerGuid, requestId) => {

  return privGetRequest(`/emailsignatures/${signerGuid}/oneshot/${requestId}/contract`);
}

OneShotService.sign = (signerGuid, requestId, body) => {

  return privPostRequest(`/emailsignatures/${signerGuid}/oneshot/${requestId}/sign`, body);
}


export default OneShotService;