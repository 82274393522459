import Helper from "../util/helper";
import { privPostRequest, privPutRequest } from "../vidCloud/priv";

const SignatureService = {};

SignatureService.pendingSignatures = (emailId) => {
  const body = {
    "Terms": {
      "EmailId": emailId
    }
  };

  return privPostRequest(`/pendingsignatures/searches`, body);
}

SignatureService.signatureFormResponse = (signerGuid, form, formResponse, isAdvancedForm) => {
  const formResponseDTO = Helper.responsesToForm(form, formResponse, isAdvancedForm);
  return privPutRequest(`/signatures/${signerGuid}/formresponse`, formResponseDTO);
}
export default SignatureService;