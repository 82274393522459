import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { IS_CUSTOM } from "../../../constants/GeneralConstants";
import documentLoadingGif from './../../../assets/images/logo_vidsigner_green.gif';
import whiteLoaderGif from '../../../assets/images/white_loader.gif';

export const RedirectingModal = ({ isRedirecting }) => {
    const { t } = useTranslation();
    const [isCustom, setIsCustom] = useState(false);

    // On Init
    useEffect(() => {
        const isCustom = localStorage.getItem(IS_CUSTOM) === "true";
        setIsCustom(isCustom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            title={t('document_detail.redirecting')}
            footer={null}
            open={isRedirecting}
            closable={false}>
            <div className="loader-container">
                <img src={isCustom ? whiteLoaderGif : documentLoadingGif} alt="LoadingGif" />
            </div>
        </Modal>
    );
};