import React, { useEffect, useState } from 'react';

export const CancelIcon = () => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-secondary-text-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L11 11" stroke={color} strokeWidth="2" />
        <path d="M11 1L0.999999 11" stroke={color} strokeWidth="2" />
      </svg>
    </>
  );
}